<template>
  <v-card>
    <v-toolbar flat dense color="secondary" dark>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-list two-line subheader>
        <template v-for="(item, index) in messages">
          <v-list-item :key="item.id" @click="showMessage(item)">
            <v-list-item-content>
              <d-avatar
                :text="item.professional.name"
                size="35"
                rounded
                :two-letter="true"
                :src="item.professional.image"
              />
              <div class="professionalName">{{ item.professional.name }}</div>
              <div class="subjectMessage">{{ item.subject }}</div>
            </v-list-item-content>
            <v-list-item-action class="grey--text">
              {{ item.fecha }}
            </v-list-item-action>
            <v-list-item-action>
              <v-badge color="red" overlap :content="item.unreadMessagesCount" :value="item.unreadMessagesCount">
                <v-btn icon @click="showMessage(item)">
                  <v-icon color="grey">mdi-message-text</v-icon>
                </v-btn>
              </v-badge>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < messages.length" :key="'d-' + item.id" />
        </template>
      </v-list>
      <div v-if="isLoading" class="py-2 text-center">
        <v-progress-circular width="2" indeterminate color="primary" />
      </div>
      <div v-else-if="messages.length === 0" class="alertNotMessages py-5 grey--text text-center">
        {{ $t('messages.not_have_pending') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { getHeader, getMessagesUrl, urlPatient } from '../../../config/config';
import DAvatar from '../../DAvatar.vue';

export default {
  name: 'LatestMessages',
  components: { DAvatar },
  props: {
    title: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },
  data: () => ({
    isLoading: false,
    messages: [],
  }),
  mounted() {
    this.loadLastMessages();
  },
  methods: {
    loadLastMessages() {
      this.isLoading = true;
      this.$http
        .get(urlPatient(getMessagesUrl, 0, 0) + '/latest', { headers: getHeader() })
        .then(res => {
          this.setMessages(res.body);
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showMessage(item) {
      this.$router.push('/mymessages/' + item.id);
    },
    setMessages(data) {
      data.forEach(function(msg) {
        if (msg.subject.length > 35) {
          msg.subject = msg.subject.substr(0, 25) + '...';
        }
      });
      this.messages = data;
    },
  },
};
</script>

<style>
.alertNotMessages {
  margin-top: -8px;
}
.professionalName {
  position: absolute;
  top: 20px;
  left: 60px;
}
.subjectMessage {
  position: absolute;
  top: 36px;
  left: 60px;
}
.noty-new-message {
  position: absolute !important;
  top: 20px;
  right: 0px;
}
</style>

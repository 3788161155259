<template>
  <v-card :loading="loading">
    <v-card-title class="title lighten-1">{{ $t('common.notice') }}</v-card-title>
    <v-card-text class="pa-4">
      {{ $t('contracts.you_have_pending_contracts', { name: name }) }}
      <v-card-text v-if="roles.length > 1">
        <v-layout>
          <v-flex xs12>
            <v-select
              v-model="professionalIdSelected"
              :items="roles"
              :label="$t('contracts.select_profile')"
              auto
              required
              item-text="company_name"
              item-value="docline_api_id"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <div class="actions">
        <v-btn color="primary" :disabled="loading" dark text @click="$emit('close')">
          {{ $t('common.close') }}
        </v-btn>
        <v-btn color="primary" :disabled="loading" dark text @click="handlerRequest('decline')">
          {{ $t('verifyusers.reject') }}
        </v-btn>
        <v-btn color="primary" :disabled="loading" depressed class="white--text" @click="handlerRequest('accept')">
          {{ $t('common.aceptar') }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { getHeader, marketplaceUrl } from '@/config/config';
import Alerts from '@/mixins/Alerts';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PendingContracts',
  mixins: [Alerts],
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    contractId: {
      type: String,
      required: true,
      default: null,
    },
  },
  data: () => ({
    roles: JSON.parse(window.localStorage.getItem('auth_ehealth'))
      ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['roles']
      : [],
    professionalIdSelected: '',
    loading: false,
  }),
  computed: {
    ...mapGetters({
      contracts: 'user/getContracts',
    }),
  },
  mounted: function() {
    this.roles = this.roles.filter(item => item.role_id === 2);
    this.professionalIdSelected = this.roles.length > 0 ? this.roles[0].docline_api_id : '';
  },
  methods: {
    ...mapActions({
      setContracts: 'user/setContracts',
    }),
    async handlerRequest(type) {
      try {
        this.loading = true;
        const response = await this.$http.put(
          `${marketplaceUrl}contracts/${this.contractId}/${type}`,
          {
            professionalIdSelected: this.professionalIdSelected,
          },
          {
            headers: getHeader(),
          }
        );
        if (response.status === 200) {
          this.toastSuccess(this.$t(`contracts.${type}_success`, { name: this.name }));
          this.setContracts(
            this.contracts.filter(el => {
              return el.id !== this.contractId;
            })
          );
        }
      } catch (e) {
        this.toastError(e.data && e.data.error ? this.$t(e.data.error) : this.$t('errors.try_again'));
      } finally {
        this.loading = false;
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}
</style>

<template>
  <v-card elevation="1">
    <v-toolbar flat dense>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="loading" class="text-center pa-5">
      <v-progress-circular width="2" color="primary" size="30" indeterminate />
    </v-card-text>
    <v-card-text v-else class="pa-0">
      <v-list two-line subheader class="pb-0">
        <template v-for="(item, index) in consultas">
          <v-list-item :key="'l_' + index">
            <v-list-item-action class="mr-4">
              <v-card rounded outlined height="48px" width="40px" class="text-center ma-0 pa-0">
                <div class="primary white--text text-uppercase caption">{{ item.mes }}</div>
                <div class="font-weight-bold text-h7">{{ item.dia }}</div>
              </v-card>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  v-if="item.paciente_id"
                  tag="a"
                  class="black--text text-decoration-none"
                  :to="'/paciente/' + item.paciente_id"
                  >{{ item.paciente }}</router-link
                >
                <template v-if="!item.paciente_id">
                  {{ item.paciente }}
                </template>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.texto }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="showLinkVideoconsultation">
              <v-chip label x-small>{{ item.hora }}</v-chip>
              <v-btn v-if="item.code !== ''" icon :href="appVideoUrl + '/c/' + item.code" target="_blank">
                <v-icon>mdi-video</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <div v-if="consultas.length === 0 && !error" class="py-7 grey--text text-center">
        {{ $t('appointments.not_have_pending') }}
      </div>
      <div v-if="error" class="py-7 grey--text text-center">
        {{ $t('appointments.error') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { appVideoUrl } from '../../../config/config';

export default {
  name: 'NextConsultations',
  props: {
    consultas: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => '',
    },
    companyName: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appVideoUrl: appVideoUrl,
      showLinkVideoconsultation:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 3,
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },
};
</script>

<template>
  <v-card>
    <v-toolbar flat dense color="secondary" dark>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-list two-line subheader>
        <template v-for="(item, index) in consultations">
          <v-list-item :key="item.id" @click="action(item.btnActionClick, item)">
            <v-list-item-content>
              <d-avatar
                :text="item.professional.name"
                size="35"
                rounded
                :two-letter="true"
                :src="item.professional.image"
              />
              <div class="professionalName">{{ item.professional.name }}</div>
              <div class="dateAppointment">
                {{ item.startAt | moment('DD/MM/YYYY HH:mm') }}
              </div>
            </v-list-item-content>
            <v-list-item-action class="grey--text">
              {{ item.fecha }}
            </v-list-item-action>
            <v-list-item-action>
              <v-btn v-tooltip="item.btnActionTooltip" icon @click="action(item.btnActionClick, item)">
                <v-icon color="grey">{{ item.btnActionIcon }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < consultations.length" :key="'d-' + item.id" />
        </template>
      </v-list>
      <div v-if="isLoading" class="py-2 text-center">
        <v-progress-circular width="2" indeterminate color="primary" />
      </div>
      <div v-else-if="consultations.length === 0" class="alertNotMessages py-5 grey--text text-center">
        {{ $t('appointments.not_have_pending') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { getAppointmentsUrl, getHeader, urlPatient } from '../../../config/config';
import DAvatar from '../../DAvatar.vue';

export default {
  name: 'UpcomingsConsultations',
  components: { DAvatar },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isLoading: false,
    consultations: [],
  }),
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },
  mounted() {
    this.loadNextConsultation();
  },
  methods: {
    loadNextConsultation() {
      this.isLoading = true;
      this.$http
        .get(urlPatient(getAppointmentsUrl, 0, 0) + '/upcomings', { headers: getHeader() })
        .then(res => {
          this.consultations = this.setData(res.body);
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setData(appointments) {
      appointments.forEach(item => {
        if (!item.paid) {
          item.statusColor = 'orange';
          item.statusText = this.$t('videoconsultations.pending_payment');
          item.btnActionIcon = 'mdi-credit-card-outline';
          item.btnActionTooltip = this.$t('videoconsultations.pay_video');
          item.btnActionClick = 'gotopay';
        } else if (item.confirmed) {
          item.statusColor = 'green';
          item.statusText = this.$t('videoconsultations.confirmed');
          item.btnActionIcon = 'mdi-video-wireless-outline';
          item.btnActionTooltip = this.$t('videoconsultations.go_to_video');
          item.btnActionClick = 'gotovideo';
        } else {
          item.statusColor = 'red';
          item.statusText = this.$t('videoconsultations.pending_confirm');
          item.btnActionIcon = 'mdi-information-outline';
          item.btnActionTooltip = this.$t('videoconsultations.pending_confirm_explain');
        }
      });
      return appointments;
    },
    action(option, item) {
      if (option === 'gotovideo') {
        window.open(item.videoconsultation.url, '_blank');
      } else if (option === 'gotopay') {
        this.$router.push('/pay/' + item.professional.id + '/appointment/' + item.id);
      }
    },
  },
};
</script>

<style>
.alertNotMessages {
  margin-top: -8px;
}
.professionalName {
  position: absolute;
  top: 4px;
  left: 60px;
}
.dateAppointment {
  position: absolute;
  top: 36px;
  left: 60px;
}
</style>

<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" width="100%" max-width="1100" class="transparent mb-5">
        <v-layout wrap>
          <v-flex sm6 xs12 class="block">
            <UpcomingsConsultations :title="$tc('common.prox_consultas', 2)" />
          </v-flex>
          <v-flex sm6 xs12 class="block">
            <LatestMessages :title="$t('common.ult_mensajes')" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import UpcomingsConsultations from '../../components/patients/dashboard/UpcomingsConsultations.vue';
import LatestMessages from '../../components/patients/dashboard/LatestMessages.vue';

export default {
  name: 'HomePatient',
  components: {
    LatestMessages,
    UpcomingsConsultations,
  },
};
</script>
<style>
.block {
  padding: 12px;
}
</style>

<template>
  <v-form ref="personalDataForm" class="p-0">
    <v-layout wrap>
      <v-flex sm6 lg6>
        <v-text-field
          v-model="usuario.name"
          filled
          outlined
          dense
          class="mx-1"
          :label="`${this.$t('patient.first_name')}*`"
          type="text"
          required
          :rules="rules.nameOrSurname"
          @change="modificarSlug"
        />
      </v-flex>
      <v-flex sm6 lg6>
        <v-text-field
          v-model="usuario.surname"
          filled
          outlined
          dense
          class="mx-1"
          :label="`${this.$t('patient.last_name')}*`"
          type="text"
          required
          :rules="rules.nameOrSurname"
          @change="modificarSlug"
        />
      </v-flex>
      <v-flex sm7 lg8 class="pr-2">
        <v-identify-document
          :dense="true"
          :filled="true"
          :id-document="usuario.id_document"
          :id-document-type="usuario.id_document_type"
          class="pr-1"
          use-required
          @onError="handleIdErrors"
          @input="setIdDocument"
        />
      </v-flex>
      <v-flex xs12 sm5 lg4>
        <v-menu
          ref="birthdateMenu"
          v-model="birthdateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          :return-value.sync="usuario.birthdate"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormatted"
              filled
              outlined
              dense
              class="mx-1"
              :label="`${$t('patient.birthdate')}*`"
              append-icon="mdi-calendar"
              readonly
              :rules="rules.requiredField"
              v-on="on"
              @blur="date = parseDate(dateFormatted)"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="date"
            first-day-of-week="1"
            :locale="this.$i18n.locale"
            :min="dateMin"
            :max="dateMax"
            no-title
            scrollable
            @input="$refs.birthdateMenu.save(date)"
          />
        </v-menu>
      </v-flex>
      <v-flex sm6 lg6>
        <v-tel-field
          id="phone"
          v-model="usuario.phone"
          filled
          dense
          outlined
          class="mx-1"
          :placeholder="`${this.$t('patient.phone')}*`"
          :rules="rules.phoneRequired"
          v-bind="bindProps"
          @validate="
            e => {
              validatePhoneHandler(e, 'phone');
            }
          "
        />
      </v-flex>
      <v-flex sm6 lg6>
        <v-tel-field
          v-model="usuario.phoneConsultation"
          filled
          dense
          outlined
          class="mx-1"
          :placeholder="`${this.$t('user.telefono_consulta')}*`"
          :rules="rules.phoneRequired"
          v-bind="bindPropsConsulta"
          @validate="
            e => {
              validatePhoneHandler(e, 'phoneConsultation');
            }
          "
        />
      </v-flex>
      <v-flex v-if="!isAdmin" xs6 sm6 lg6>
        <v-select
          v-model="usuario.prefix"
          filled
          outlined
          dense
          class="mx-1"
          :label="$t('user.salutation')"
          item-text="label"
          item-value="value"
          :items="prefixs"
          @change="modificarSlug"
        />
      </v-flex>
      <v-flex v-if="!isAdmin && usuario.is_health_personnel" sm6 lg6>
        <v-text-field
          v-model="usuario.collegiateNumber"
          filled
          outlined
          dense
          class="mx-1"
          :label="`${this.$t('user.num_colegiado')}*`"
          :rules="rules.collegiateNumber"
          type="text"
        />
      </v-flex>
      <v-flex xs12 class="text-right">
        <v-btn text depressed class="mr-2" @click="$emit('cerrar-step', true)">{{ $t('common.cancel') }}</v-btn>
        <v-btn depressed :loading="isLoading" color="primary" @click="savePersonalData">{{
          $t('common.continuar')
        }}</v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import { saveStepToStepUrl, getHeader, defaultCountry } from '@/config/config';
import { datePickerMinDate } from '@/services/dateHelper';
import FormValidator from '@/mixins/FormValidation';
import VIdentifyDocument from '../../../modules/VuetifyIdentityDocument/components/VIdentifyDocument.vue';

export default {
  name: 'StepPersonalData',
  components: {
    VIdentifyDocument,
  },
  mixins: [FormValidator],
  props: {
    usuario: {
      type: Object,
      default: () => [],
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      dateFormatted: null,
      birthdateMenu: false,
      dateMax: new Date().toISOString().substr(0, 10),
      isLoading: false,
      bindProps: {
        mode: 'international',
        defaultCountry: JSON.parse(window.localStorage.getItem('auth_ehealth'))
          ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
          : 'ES',
        preferredCountries: ['ES', 'FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      bindPropsConsulta: {
        mode: 'international',
        defaultCountry: JSON.parse(window.localStorage.getItem('auth_ehealth'))
          ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
          : 'ES',
        preferredCountries: ['ES', 'FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone2',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      prefixs: [],
      requiredCollegiateNumber: false,
      dateMin: datePickerMinDate(),
      rules: {},
      inputsAreValid: {
        documentId: true,
        phoneConsultation: true,
        phone: true,
      },
    };
  },

  watch: {
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    birthdateMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
    usuario: {
      handler: function(val) {
        this.date = val.birthdate;
      },
    },
  },
  created() {
    this.setDefaults();
  },
  mounted() {
    this.date = this.usuario.birthdate;
    this.dateFormatted = this.formatDate(this.date);
    this.setPrefix(this.usuario.is_health_personnel);
    const now = new Date();
    now.setFullYear(now.getFullYear() - 18);
    this.dateMax = now.toISOString().substr(0, 10);
  },
  methods: {
    setPrefix(is_health_personnel) {
      const female = is_health_personnel
        ? { value: 'Dra.', label: this.$t('user.female_doctor'), color: 'pink' }
        : {
            value: 'Sra.',
            label: this.$t('user.female_person'),
            color: 'pink',
          };
      const male = is_health_personnel
        ? { value: 'Dr.', label: this.$t('user.male_doctor'), color: 'blue' }
        : { value: 'Sr.', label: this.$t('user.male_person'), color: 'blue' };

      this.prefixs = [{ value: '0', label: this.$t('common.none'), color: 'grey' }, female, male];
    },

    modificarSlug() {
      let slug = '';
      let nombreMarket = '';
      if (
        typeof this.usuario.prefijo !== 'undefined' &&
        this.usuario.prefijo !== '0' &&
        this.usuario.prefijo !== 0 &&
        this.usuario.prefijo !== ''
      ) {
        nombreMarket = this.usuario.prefijo + ' ';
        const pref = this.usuario.prefijo.replace('.', '');
        slug += pref.toLowerCase() + '-';
      }
      if (this.usuario.name !== '') {
        let nombre = this.usuario.name.trim();
        nombre = nombre.replace(/\s/g, '-');
        nombre = nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += nombre.toLowerCase() + '-';
        nombreMarket += this.usuario.name + ' ';
      }
      if (this.usuario.surname !== '') {
        let apellidos = this.usuario.surname.trim();
        nombreMarket += apellidos;
        apellidos = this.usuario.surname.replace(/\s/g, '-');
        apellidos = apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += apellidos.toLowerCase();
      }
      this.usuario.slug = slug;
      this.usuario.publicName = nombreMarket;
    },

    clearString(str) {
      return str.replace(/ +/g, '').replace(/-/g, '');
    },

    handleIdErrors() {
      this.$nextTick(() => {
        this.inputsAreValid.documentId = false;
      });
    },

    setIdDocument(data) {
      this.usuario.id_document = data.idDocument;
      this.usuario.id_document_type = data.idDocumentType;
      this.inputsAreValid.documentId = true;
    },

    validatePhoneHandler(phone, type) {
      this.inputsAreValid[type] = phone.valid;
    },

    checkInputsAreValid() {
      let result = true;
      if (!this.inputsAreValid.phoneConsultation || !this.inputsAreValid.phone) {
        this.$toast.error(this.$t('registro.alert_phone_is_invalid'));
        result = false;
      } else if (!this.inputsAreValid.documentId) {
        this.$toast.error(this.$t('patient.dni_invalid'));
        result = false;
      }
      return result;
    },

    savePersonalData() {
      if (!this.checkInputsAreValid() || !this.$refs.personalDataForm.validate()) {
        return;
      }

      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      this.isLoading = true;
      this.$http
        .post(saveStepToStepUrl, { id: authUser.id, usuario: this.usuario, step: 2 }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.$emit('completeStep', 1);
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    setDefaults() {
      if (defaultCountry) {
        this.bindProps.defaultCountry = defaultCountry;
        this.bindProps.preferredCountries.unshift(defaultCountry);
      }
    },
  },
};
</script>

<template>
  <v-form ref="clinicalDataForm" class="p-0">
    <v-layout wrap>
      <v-flex sm6 lg6>
        <v-text-field
          v-model="empresa.nombre"
          class="mr-2"
          filled
          outlined
          dense
          :label="$t('common.name') + ' (' + $t('empresa.empresa') + ')*'"
          type="text"
          :rules="rules.nameOrSurname"
        />
      </v-flex>

      <v-flex sm6 lg6>
        <v-text-field
          v-model="empresa.razon_social"
          class="mr-2"
          filled
          outlined
          dense
          :label="`${this.$t('empresa.razon_social')}*`"
          type="text"
          :rules="rules.nameOrSurname"
        />
      </v-flex>

      <v-flex sm6 lg6>
        <v-text-field
          v-model="empresa.cif"
          class="mr-2"
          filled
          outlined
          dense
          :label="`${this.$t('empresa.cif')}*`"
          type="text"
          :rules="rules.requiredField"
        />
      </v-flex>

      <v-flex sm6 lg6>
        <v-tel-field
          id="phoneClinica"
          v-model="empresa.phone"
          filled
          dense
          outlined
          class="mx-1"
          :placeholder="$t('patient.phone') + ' (' + $t('menu.contact') + ')*'"
          v-bind="bindPropsClinica"
          :rules="rules.phoneRequired"
          @validate="validatePhoneHandler"
        />
      </v-flex>

      <v-flex sm6 lg6>
        <v-text-field
          v-model="empresa.email_admin"
          class="mr-2"
          filled
          dense
          outlined
          :label="$t('message.email') + ' (' + $t('menu.contact') + ')*'"
          append-icon="mdi-at"
          type="mail"
          :rules="rules.emailRequired"
        />
      </v-flex>

      <v-flex sm6 lg6>
        <v-text-field
          v-model="empresa.web"
          class="mr-2"
          filled
          dense
          outlined
          :label="`${this.$t('empresa.web')}*`"
          append-icon="mdi-tablet-dashboard"
          type="text"
          :rules="rules.invalidUrl"
        />
      </v-flex>

      <v-flex xs12 class="text-right">
        <v-btn depressed text @click="$emit('cerrar-step', true)">{{ $t('common.cancel') }}</v-btn>
        <v-btn depressed :loading="isLoading" color="primary" @click="saveClinicalData">{{
          $t('common.continuar')
        }}</v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import { saveStepClinicalUrl, getHeader, defaultCountry } from '@/config/config';
import FormValidator from '@/mixins/FormValidation';

export default {
  name: 'StepClinicalData',
  mixins: [FormValidator],
  props: {
    empresa: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isLoading: false,
    dateMaxNacimiento: new Date().toISOString().substr(0, 10),
    bindPropsClinica: {
      mode: 'international',
      defaultCountry: JSON.parse(window.localStorage.getItem('auth_ehealth'))
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
        : 'ES',
      preferredCountries: ['ES', 'FR', 'PT'],
      disabledFetchingCountry: false,
      disabled: false,
      disabledFormatting: false,
      required: false,
      enabledCountryCode: false,
      enabledFlags: true,
      onlyCountries: [],
      ignoredCountries: [],
      autocomplete: 'off',
      name: 'phoneClinica',
      maxLen: 17,
      wrapperClasses: '',
      inputClasses: '',
      dropdownOptions: {
        disabledDialCode: false,
      },
      inputOptions: {
        showDialCode: false,
      },
      rules: {},
      phoneIsValid: true,
    },
  }),

  watch: {
    empresa: {
      handler() {
        this.$refs.clinicalDataForm.resetValidation();
      },
      deep: true
    },
  },

  created() {
    this.setDefaults();
  },
  methods: {
    handleClick: e => {
      console.log(e);
    },

    validatePhoneHandler(phone) {
      this.phoneIsValid = phone.valid;
    },

    validateClinicalData() {
      let res = true;

      if (!this.phoneIsValid) {
        this.$toast.error(this.$t('errors.format_phone'));
        res = false;
      }

      return res;
    },

    saveClinicalData() {
      if (!this.$refs.clinicalDataForm.validate() || !this.validateClinicalData()) {
        return;
      }

      this.isLoading = true;
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      this.$http
        .post(saveStepClinicalUrl, { id: authUser.id, empresa: this.empresa, step: 2 }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.$emit('completeStep', 1);
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setDefaults() {
      if (defaultCountry) {
        this.bindPropsClinica.defaultCountry = defaultCountry;
        this.bindPropsClinica.preferredCountries.unshift(defaultCountry);
      }
    },
  },
};
</script>
<style>
.vue-tel-input {
  margin-top: 10px;
  width: 98%;
  margin-bottom: 10px;
}

.label_tel {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9rem;
}

.text-xs-rig {
  text-align: right !important;
}
</style>

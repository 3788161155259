
import Vue from 'vue';
import SelectableRectangle from '@/components/atoms/SelectableRectangle.vue';
import customvmodelMixin from '@/mixins/custom-vmodel.mixin.js'

export default Vue.extend({
  components: { SelectableRectangle },
  mixins: [ customvmodelMixin ],
  props: {
    items: {
      type: Array,
      default: function() {
        return [];
      },
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    itemId: {
      type: String,
      default: 'id',
    },
    itemText: {
      type: String,
      default: 'name',
    },
  },
});

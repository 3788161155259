<template>
  <v-layout wrap>
    <quick-button
      v-if="canCreateUser || esadmin"
      icon-name="mdi-account-multiple-plus"
      @click="handleOnClick('/usuarios/nuevo')"
      >{{ $t('user.nuevo') }}
    </quick-button>

    <quick-button v-if="!esadmin && !es_rrhh && !hidePatientCreation" data-test="init-button-new-patient" icon-name="mdi-account-plus" @click="handleOnClick('/pacientes/nuevo')">
      {{ $t('patient.new') }}
    </quick-button>

    <quick-button v-if="this.canEmit" data-test="init-button-prescription" icon-name="mdi-text-box-plus" @click="choosePrescription">
      {{ $t('common.emitir_receta') }}
    </quick-button>

    <quick-button
      v-if="!esadmin && !es_rrhh && videoconsultationEnabled"
      data-test="init-button-new-video"
      icon-name="mdi-video"
      @click="handleOnClick('/videoconsulta/nuevo')"
    >
      {{ $t('videoconsulta.nueva_video') }}
    </quick-button>

    <quick-button
      v-if="showMessageModule && permissions.messaging && !esadmin && !es_rrhh"
      data-test="init-button-new-message"
      icon-name="mdi-message-plus"
      @click="handleOnClick('/mensajeria/nuevo')"
    >
      {{ $t('mensajeria.nuevo_mensaje') }}
    </quick-button>

    <quick-button
      v-if="!esadmin && permissions.moduleMedicalTests && !loading"
      data-test="init-button-new-test"
      icon-name="mdi-file-document-outline"
      @click="chooseMedicalTest"
    >
      {{ $t('medicalTests.new_test') }}
    </quick-button>

    <quick-button
      v-if="allowWebPhone"
      data-test="init-button-new-call" icon-name="mdi-phone" @click="$dialog.call">
      {{ $t('webphone.new_call') }}
    </quick-button>

    <v-dialog v-model="dialogChooseMedicalTest" max-width="500px">
      <v-card>
        <v-card-title class="hidden-xs-only">
          <span class="text-h5">{{ $t('medicalTests.select_medical_test_type') }}:</span>
        </v-card-title>
        <v-list>
          <v-list-item @click="openSelectPatientMedicalTests(analyticalTest)">
            <v-list-item-content>
              <v-list-item-title>{{ $t('medicalTests.analytical_test') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openSelectPatientMedicalTests(rxTest)">
            <v-list-item-content>
              <v-list-item-title>{{ $t('medicalTests.rx_test') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openSelectPatientMedicalTests(otherTest)">
            <v-list-item-content>
              <v-list-item-title>{{ $t('medicalTests.other_test') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click.stop="dialogChooseMedicalTest = false">{{ $t('common.cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import QuickButton from "./QuickButton.vue";
import PermissionsMixin from "@/mixins/Permissions";

export const ANALYTICAL_TEST = 'analytical';
export const RX_TEST = 'rx';
export const OTHER_TEST = 'other';

export default {
  name: 'Botonera',
  components: { QuickButton },
  mixins: [
    PermissionsMixin
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      is_receptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      canCreateUser:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3 &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== '',
      es_rrhh:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 4,
      esadmin:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
      showMessageModule:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['messagingPermission'] === 1,
      showElectronicPrescription: false,
      dialogChooseRecipe: false,
      dialogChooseMedicalTest: false,
      isEprescriptionUser: false,
      allowWebPhone:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['servicios_activos']['webphone'] === 1,
      showRx: false
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
      analyticalTest() {
        return ANALYTICAL_TEST;
      },
      rxTest() {
        return RX_TEST;
      },
      otherTest() {
        return OTHER_TEST;
      },
    }),
    hidePatientCreation() {
      return this.companyPermissions?.hidePatientCreation;
    },
    videoconsultationEnabled() {
      return this.authUser?.servicios_activos?.videoconsulta;
    }
  },

  methods: {
    choosePrescription() {
      this.$emit('showDialogChoosePrescription');
    },

    chooseMedicalTest() {
      this.dialogChooseMedicalTest = true;
    },

    openSelectPatientMedicalTests(medicalTestType) {
      this.dialogChooseMedicalTest = false;
      this.$emit('showPatientModal', medicalTestType);
    },

    handleOnClick(route) {
      this.$router.push(route);
    },
  },
};
</script>

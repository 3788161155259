<template>
  <v-card elevation="1" flat>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="loading" class="text-center pa-5">
      <v-progress-circular width="2" color="primary" size="30" indeterminate />
    </v-card-text>
    <v-card-text v-else class="pa-0">
      <v-list two-line subheader class="pb-0">
        <template v-for="item in consultas">
          <v-list-item :key="item.id" @click="goToMessageOfPatient(item.id, item.paciente_id)">
            <v-list-item-avatar>
              <d-avatar :text="item.fullName" :src="item.avatar" rounded size="32" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.fullName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.texto !== '' ? item.texto : item.mensaje }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="grey--text">
              {{ item.fecha }}
              <v-chip v-if="item.contestado === 0" x-small label> {{ $t('mensajeria.sin_contestar') }} </v-chip>
              <v-chip v-if="item.contestado === 1" x-small label> {{ $t('mensajeria.answered') }} </v-chip>
              <v-chip v-if="item.contestado === 2" x-small label> {{ $t('mensajeria.sent') }} </v-chip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <div v-if="consultas.length === 0 && !error" class="py-7 grey--text text-center">
        {{ $t('messages.not_have_pending') }}
      </div>
      <div v-if="error" class="py-7 grey--text text-center">
        {{ $t('messages.error') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import DAvatar from '@/components/DAvatar';
import { setReadThreadById } from '@/views/messaging/api';

export default {
  name: 'LastMessages',
  components: {
    DAvatar,
  },
  props: {
    consultas: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },
  methods: {
    goToMessageOfPatient(id, patientId) {
      if (this.markMessagesRead(id)) {
        const tabName = this.$t('common.messaging')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        if (this.markMessagesRead(id)) {
          this.$router.push('/paciente/' + patientId + '/' + tabName + '/' + id);
        }
      }
    },
    async markMessagesRead(threadId) {
      const role = this.authUser.isPatient ? 'patient' : 'professional';
      return await setReadThreadById(this.authUser.user.id, role, threadId);
    },
  },
};
</script>

<template>
  <div v-if="prescriptionTypes">
    <StepModal
      v-model="modalValue"
      :title="$t('common.emitir_receta')"
      :current-step="currentStep"
      :steps="steps"
      :show-steps="showSteps"
      icon-name="mdi-text-box-plus"
    >
      <div v-if="canShowPrescriptionSelectorModal" class="prescription-selector-modal__container">
        <v-stepper v-model="currentStep" :steps="steps" :elevation="0" flat>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="prescription-selector-modal__content">
                <RectangleSelector
                  v-model="prescriptionTypeSelected"
                  :label="$t('prescription_selector.select_prescription')"
                  :items="availableTypes"
                  name="prescriptionType"
                />
                <v-divider v-if="showTemplateSelector" class="prescription-selector-modal__divider" />
                <ParapharmacyTemplateSelector v-if="showTemplateSelector" @templateSelected="templateSelected" @loadedTemplates="loadedTemplates" />
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="prescription-selector-modal__content">
                <PatientsSelector v-if="currentStep === 2" @patientSelected="patientSelected" />
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
      <template v-if="showSteps && canShowPrescriptionSelectorModal" v-slot:actions>
        <DButton :disabled="!canReturnBack" :primary="true" @click.native="returnBack">{{
          $t('common.return_back')
        }}</DButton>
      </template>

      <CheckingCollegiateNumber v-if="checkingCollegiateNumber" />
      <CollegiateMsg v-else-if="!isValidCollegiateNumber" />
      <CollegiateNotAllowToPrescribeMsg v-else-if="!collegiateNumberAllowEmmit" />
    </StepModal>
  </div>
</template>

<script>
import StepModal from '@/components/StepModal/StepModal.vue';
import ParapharmacyTemplateSelector from '@/components/Parapharmacy/ParapharmacyTemplateSelector/ParapharmacyTemplateSelector.vue';
import DButton from '@/components/atoms/DButton.vue';
import PatientsSelector from '@/components/patients/PatientsSelector/PatientsSelector.vue';
import RectangleSelector from '@/components/molecules/RectangleSelector.vue';
import { mapGetters, mapMutations } from 'vuex';
import { baseNameRoutes } from '@/router/paths';
import { checkCollegiateAllowPrescribe, getHeader, professionalHasSign } from '@/config/config';
import { haveValidCollegiateNumber } from '@/utils';
import CollegiateNotAllowToPrescribeMsg from '@/components/treatments/CollegiateNotAllowToPrescribeMsg.vue';
import CollegiateMsg from '@/components/treatments/CollegiateMsg.vue';
import CheckingCollegiateNumber from '@/components/treatments/CheckingCollegiateNumber.vue';
import PermissionsMixin from "@/mixins/Permissions";

export const PRESCRIPTION_TYPES = Object.freeze({
  PDF: 'pdfPrescription',
  ELECTRONIC: 'electronicPrescription',
  PARAPHARMACY: 'parapharmacyPrescription',
});

export default {
  name: 'PrescriptionSelectorModal',
  components: {
    CollegiateMsg,
    CollegiateNotAllowToPrescribeMsg,
    CheckingCollegiateNumber,
    RectangleSelector,
    PatientsSelector,
    DButton,
    ParapharmacyTemplateSelector,
    StepModal,
  },
  mixins: [
    PermissionsMixin
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    preselectedType: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      currentStep: 1,
      steps: 2,
      prescriptionTypes: null,
      prescriptionTypeSelected: null,
      template: null,
      isValidCollegiateNumber: true,
      collegiateNumberAllowEmmit: true,
      checkingCollegiateNumber: false,
      needToCheckCollegiateNumber: true,
    };
  },
  computed: {
    ...mapGetters({
      twoFaEnabled: 'account/twoFaEnabled',
      useNewAccountPage: 'app/getFeatureNewAccountPageFlag',
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    canReturnBack() {
      return this.currentStep > 1;
    },

    availableTypes() {
      return this.prescriptionTypes.filter(type => {
        return type.available;
      });
    },

    canShowPrescriptionSelectorModal() {
      return !this.checkingCollegiateNumber && this.isValidCollegiateNumber && this.collegiateNumberAllowEmmit;
    },

    availablesSize() {
      return this.prescriptionTypes.filter(prescriptionType => prescriptionType.available).length;
    },

    showTemplateSelector() {
      return this.prescriptionTypeSelected === PRESCRIPTION_TYPES.PARAPHARMACY;
    },

    showSteps() {
      return !this.onlyHaveThisAvailable(PRESCRIPTION_TYPES.ELECTRONIC);
    },

    need2fa() {
      return !this.twoFaEnabled && this.useNewAccountPage && this.repHomologation;
    },
  },

  watch: {
    prescriptionTypeSelected(val) {
      if (val) {
        this.onPrescriptionTypeSelected(val);
      }
    },
  },

  created() {
    this.loadAvailables();
    if (this.preselectedType) {
      this.prescriptionTypeSelected = this.preselectedType;
      this.needToCheckCollegiateNumber = false;
    }
  },

  methods: {
    ...mapMutations({
      setTwoFaDialogVisibility: 'layout/setTwoFaDialogVisibility',
    }),

    loadAvailables() {
      this.prescriptionTypes = [
        { name: 'Receta PDF', id: PRESCRIPTION_TYPES.PDF, available: this.pdfPrescriptionAvailable, loading: false },
        {
          name: 'Receta Electrónica Privada',
          id: PRESCRIPTION_TYPES.ELECTRONIC,
          available: this.electronicPrescriptionAvailable,
          loading: false,
        },
        {
          name: 'Receta de Parafarmacia',
          id: PRESCRIPTION_TYPES.PARAPHARMACY,
          available: this.parapharmacyPrescriptionAvailable,
          loading: false,
        },
      ];
    },

    reset() {
      this.currentStep = 1;
      this.prescriptionTypeSelected = null;
      this.template = null;
    },
    onlyHaveThisAvailable(prescriptionType) {
      return (
        this.prescriptionTypes.some(type => type.available && type.id === prescriptionType) && this.availablesSize === 1
      );
    },

    returnBack() {
      if (this.currentStep > 1) {
        this.prescriptionTypeSelected = null;
        this.currentStep -= 1;
      }
    },

    goToPrescriptionPDF() {
      this.$router.push('/receta');
    },

    async checkCollegiateNumberAllowToPrescribe() {
      this.isValidCollegiateNumber = haveValidCollegiateNumber();
      if (!this.isValidCollegiateNumber) {
        return;
      }

      const response = await this.$http.post(
        checkCollegiateAllowPrescribe,
        { professional_id: this.authUser.user.id },
        { headers: getHeader() }
      );
      return response.body.allow;
    },

    async onPrescriptionTypeSelected(prescriptionType) {
      switch (prescriptionType) {
        case 'pdfPrescription':
          await this.professionalHasSign();
          this.goToPrescriptionPDF();
          break;
        case 'electronicPrescription':
          try {
            if (this.needToCheckCollegiateNumber) {
              this.checkingCollegiateNumber = true;
              this.collegiateNumberAllowEmmit = await this.checkCollegiateNumberAllowToPrescribe();
              if (!this.collegiateNumberAllowEmmit) {
                return;
              }
            }
          } catch (e) {
            this.isValidCollegiateNumber = false;
          } finally {
            this.checkingCollegiateNumber = false;
          }

          if (this.need2fa) {
            this.modalValue = false;
            this.reset();
            this.setTwoFaDialogVisibility(true);
          } else {
            this.nextStep();
          }
          break;
        case 'parapharmacyPrescription':
          this.prescriptionTypes[2].loading = true;
          await this.professionalHasSign();
          break;
      }
    },

    nextStep() {
      this.currentStep += 1;
    },

    templateSelected(template) {
      this.template = template;
      this.nextStep();
    },
    patientSelected(patient) {
      if (this.prescriptionTypeSelected === PRESCRIPTION_TYPES.ELECTRONIC) {
        this.$emit('showEPrescriptionModal', patient);
      }

      if (this.prescriptionTypeSelected === PRESCRIPTION_TYPES.PARAPHARMACY) {
        this.$emit('showParapharmacyPrescriptionModal', patient, this.template);
      }

      this.reset();
    },
    async professionalHasSign() {
      try {
        const response = await this.$http.get(professionalHasSign, { headers: getHeader() });
        return response.status === 200;
      } catch (e) {
        await this.$router.push({ name: baseNameRoutes.signed });
      }
    },
    loadedTemplates(templates) {
      this.prescriptionTypes[2].loading = false;
      if (templates.length === 1) {
        this.templateSelected(templates[0].id);
      }
    }
  },
};
</script>

<style lang="scss">
.prescription-selector-modal {
  &__content {
    padding-top: 2.25rem;
  }
  &__divider {
    margin-top: 2.25rem;
    border-color: var(--v-primary-base);
  }
}

@media only screen and (min-width: 992px) {
  .prescription-selector-modal {
    &__divider {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}

.prescription-selector-modal__container {
  & .v-stepper {
    box-shadow: none !important;
  }

  & .v-stepper__content {
    padding: 0 !important;
  }
}
</style>

<template>
  <label :class="{ 'selectable-rectangle__container': true, 'selectable-rectangle--checked': isChecked }">
    <slot />
    <input v-model="localValue" type="radio" :value="radioValue" :name="name" />
  </label>
</template>

<script>
import customvmodelMixin from '@/mixins/custom-vmodel.mixin';

export default {
  name: 'SelectableRectangle',
  mixins: [customvmodelMixin],
  props: {
    name: { type: String, required: true },
    radioValue: { type: String, required: true },
  },
  computed: {
    isChecked() {
      return this.localValue === this.radioValue;
    },
  },
};
</script>

<style lang="scss">
$grey: #f1f1f1;

.selectable-rectangle {
  &__container {
    display: block;
    width: 100%;
    position: relative;
    padding: 1.25rem 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: $grey 0.125rem solid;
    border-radius: 0.375rem;
    text-align: center;
    max-height: 4rem;

    &.selectable-rectangle--checked {
      border-color: var(--v-primary-base);
    }
  }
  &__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
</style>

import {haveEprescriptionActive} from "../utils";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'PermissionsMixin',

  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
    }),
    electronicPrescriptionAvailable() {
      return (
        (this.permissions.haveElectronicPrescription && !haveEprescriptionActive()) ||
        (this.permissions.haveElectronicPrescription && haveEprescriptionActive())
      );
    },
    pdfPrescriptionAvailable() {
      return this.permissions.settingsBook && this.permissions.prescriptions;
    },
    parapharmacyPrescriptionAvailable() {
      return (
        typeof this.permissions.parapharmacyPrescription !== 'undefined' &&
        this.permissions.settingsSign &&
        ((!this.companyPermissions?.parapharmacyPrescription &&
            this.authUser?.user?.professional_parapharmacy_prescription_service) ||
          (this.companyPermissions?.parapharmacyPrescription &&
            this.authUser?.user?.professional_parapharmacy_prescription_service))
      );
    },

    canEmit() {
      return this.electronicPrescriptionAvailable || this.pdfPrescriptionAvailable || this.parapharmacyPrescriptionAvailable;
    }
  },
};

<template>
  <div>
    <v-stepper v-model="steps">
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="steps > 1" step="1">{{
          isAdmin ? $t('steps.clinical_data') : $t('patient.personal_data')
        }}</v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="steps > 2" step="2">{{
          isAdmin ? $t('steps.datos_facturacion') : $t('steps.espacio', { name: appDisplayName })
        }}</v-stepper-step>
        <v-divider v-if="usuario.payment_module === 1" />
        <v-stepper-step v-if="usuario.payment_module === 1" step="3">{{
          isAdmin ? $t('steps.representative_data') : $t('steps.datos_facturacion')
        }}</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-layout wrap class="center-cont container">
            <step-clinical-data
              v-if="isAdmin"
              :empresa="empresa"
              :is-admin="isAdmin"
              @cerrar-step="cerrarStep"
              @completeStep="completeStep"
            />
            <step-personal-data
              v-else
              :usuario="usuario"
              :is-admin="isAdmin"
              @cerrar-step="cerrarStep"
              @completeStep="completeStep"
            />
          </v-layout>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-layout wrap class="center-cont container">
            <step-billing-data
              v-if="isAdmin"
              :data="empresa"
              :company-billing="true"
              :is-admin="isAdmin"
              @back-to-step="backToStep"
              @completeStep="completeStep"
            />
            <info-to-patient
              v-else
              :especialidades="especialidades"
              :usuario="usuario"
              @back-to-step="backToStep"
              @completeStep="completeStep"
            />
          </v-layout>
        </v-stepper-content>

        <v-stepper-content step="3">
          <step-billing-data
            :data="usuario"
            :company-billing="false"
            :is-admin="isAdmin"
            @back-to-step="backToStep"
            @completeStep="completeStep"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StepPersonalData from './PersonalData.vue';
import InfoToPatient from './InfoToPatient.vue';
import StepClinicalData from './ClinicalData.vue';
import StepBillingData from './BillingData.vue';

export default {
  name: 'Steptostep',
  components: {
    StepBillingData,
    StepClinicalData,
    InfoToPatient,
    StepPersonalData,
  },
  props: {
    usuario: {
      type: Object,
      default: () => [],
    },
    empresa: {
      type: Object,
      default: () => [],
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    especialidades: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    steps: 1,
  }),

  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
      permissions: state => state.app.permissions,
    }),
  },

  methods: {
    handleClick: e => {
      console.log(e);
    },

    cerrarStep() {
      this.$emit('cerrar-step', true);
    },

    backToStep(step) {
      this.steps = step;
    },

    completeStep(step) {
      const new_step = step + 1;
      if ((new_step === 3 && this.usuario.payment_module === 0) || new_step === 4) {
        this.$emit('completadoStepToStep', true);
      }
      this.steps = new_step;
    },
  },
};
</script>
<style>
.center-cont {
  margin: 0;
}
</style>

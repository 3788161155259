<template>
  <v-dialog v-model="dialogWelcome" max-width="500px">
    <v-card>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12 class="text-center">
            <h2 class="title_dialog">{{ $t('message.welcome', { name: appDisplayName }) }}</h2>
            <h3 class="subtitle_dialog">{{ $t('message.can_help_you') }}</h3>
            <v-btn color="primary" text @click="goToHelp">{{ $t('message.get_help') }}</v-btn>
            <br />
            <v-btn color="primary" text @click="hideWelcome">{{
              $t('message.continue_to', { name: appDisplayName })
            }}</v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Welcome',
  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
      permissions: state => state.app.permissions,
    }),
  },
  props: {
    dialogWelcome: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToHelp() {
      this.$router.push('Ayuda');
    },
    hideWelcome() {
      this.$emit('hideDialogWelcome', true);
    },
  },
};
</script>

<style>
.title_dialog {
  margin: 20px 0px;
  font-weight: 100;
  color: #666;
  font-size: 2.1em;
}
.subtitle_dialog {
  margin-bottom: 5px;
  font-weight: 500;
}
</style>

<template>
  <v-layout wrap>
    <v-flex xs12 sm3>
      <div class="text-center" style="margin-top: 10px;">
        <v-tooltip v-if="!modificando_imagen" right>
          <template v-slot:activator="{ on }">
            <span class="foto_avatar" v-on="on" @click="modifyImage()">
              <d-avatar
                class="avatar foto_usuario"
                :text="usuario.publicName"
                size="150"
                rounded
                :two-letter="true"
                :src="usuario.photo"
              />
            </span>
          </template>
          <span>{{ $t('common.modify_image') }}</span>
        </v-tooltip>
        <croppa
          v-model="myCroppa"
          :class="!modificando_imagen ? estado_croppa + ' foto_avatar' : 'foto_avatar'"
          :width="150"
          :height="150"
          :placeholder="$t('user.select_image')"
          placeholder-color="#1976D2"
          :placeholder-font-size="10"
          canvas-color="transparent"
          :show-remove-button="true"
          remove-button-color="#1976D2"
          :remove-button-size="36"
          :show-loading="true"
          :loading-size="20"
          :zoom-speed="8"
          :prevent-white-space="true"
          :file-size-limit="5002400"
          @file-size-exceed="onFileSizeExceed"
        />
        <input
          id="file"
          ref="file"
          type="file"
          accept="application/pdf,image/jpg,image/png,image/gif"
          style="display: none"
          @change="onFileChange"
        />
      </div>
    </v-flex>
    <v-flex xs12 sm9>
      <v-layout wrap class="center-cont">
        <v-flex sm12 lg12>
          <v-text-field
            v-model="usuario.publicName"
            class="mr-2"
            :label="$t('user.name_patients_will_see')"
            type="text"
          />
        </v-flex>
        <v-flex v-show="usuario.is_health_personnel" xs12>
          <div slot="widget-content">
            <v-select
              ref="select"
              v-model="usuario.especialidades"
              :label="`${this.$t('user.especialidades')}*`"
              :items="especialidades"
              chips
              :item-text="$i18n.locale === 'es' ? 'name_es' : 'name_en'"
              item-value="id"
              item-valor="id"
              multiple
              @change="closeAfterSelect"
            >
              <template slot="selection" slot-scope="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  class="chip--select-multi"
                  color="blue lighten-4"
                  @click:close="removeSpecialty(data.item.id)"
                >
                  {{ $i18n.locale === 'es' ? data.item.name_es : data.item.name_en }}
                </v-chip>
              </template>
              <template slot="item" slot-scope="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item" />
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $i18n.locale === 'es' ? data.item.name_es : data.item.name_en
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
          </div>
        </v-flex>

        <v-flex xs12>
          <v-radio-group
            v-model="usuario.biography_lang"
            row
            style="margin-top: -10px; margin-bottom: -20px; float: right"
          >
            <v-radio color="blue darken-3" :label="$t('common.spanish')" value="es" />
            <v-radio color="blue darken-3" :label="$t('common.english')" value="en" />
          </v-radio-group>
        </v-flex>
        <v-flex v-if="usuario.biography_lang === 'es'" xs12 sm12 lg12>
          <v-textarea
            v-model="usuario.biography_es"
            style="width: 99%; margin-left: 4px;"
            :label="$t('user.biografia')"
            rows="3"
            placeholder="Escriba aqui el texto en Español"
          />
          <div
            :class="500 - usuario.biography_es.length < 0 ? 'font-red' : ''"
            style="font-size: 11px;margin-top: -20px;width: 99.5%;"
          >
            {{ 500 - usuario.biography_es.length }}
          </div>
        </v-flex>
        <v-flex v-if="usuario.biography_lang === 'en'" xs12 sm12 lg12>
          <v-textarea
            v-model="usuario.biography_en"
            style="width: 99%; margin-left: 4px;"
            :label="$t('user.biografia')"
            rows="3"
            placeholder="Write here the text in English"
          />
          <div
            :class="500 - usuario.biography_en.length < 0 ? 'font-red' : ''"
            style="font-size: 11px;margin-top: -20px;width: 99.5%;"
          >
            {{ 500 - usuario.biography_en.length }}
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="text-right">
      <v-btn depressed text @click="$emit('back-to-step', 1)">{{ $t('common.volver_atras') }}</v-btn>
      <v-btn depressed color="primary" :loading="isLoading" @click="saveInfoToPatient">{{
        $t('common.continuar')
      }}</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { saveStepToStepUrl, editImageUserUrl, getHeader, getHeaderMultimedia } from '@/config/config';
import DAvatar from '../../DAvatar.vue';

export default {
  name: 'InfoToPatient',
  components: { DAvatar },
  props: {
    usuario: {
      type: Object,
      default: () => [],
    },
    especialidades: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    myCroppa: {},
    isLoading: false,
    estado_croppa: 'ocultar_croppa',
    modificando_imagen: false,
    procesoCarga: 0,
  }),

  mounted() {
    document.getElementsByClassName('croppa-container')[0].style.display = 'none';
  },

  methods: {
    modifyImage() {
      document.getElementsByClassName('croppa-container')[0].style.display = '';
      this.modificando_imagen = true;
      this.estado_croppa = '';
      this.myCroppa.chooseFile();
      setTimeout(function() {
        const elementPhoto = document.getElementsByClassName('foto_avatar');
        const canvas = elementPhoto[0].childNodes[4];
        if (typeof canvas !== 'undefined' && canvas !== null) {
          canvas.style.borderRadius = '50%';
          canvas.style.boxShadow =
            '0 10px 30px -12px rgba(0,0,0,.42), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)';
        }
      }, 500);
    },

    onFileSizeExceed() {
      this.$toast.error(this.$t('common.warning_image_exceeds_maximum', { size: '1024KB' }));
    },

    onFileChange() {
      this.disabledButton = true;
      const image = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append('file', image);
      formData.append('usuario_id', this.usuario.id);
      formData.append('user_id', this.authUser.id);
    },

    validateData() {
      let res = true;

      if (this.usuario.especialidades.length < 1 && this.usuario.is_health_personnel) {
        this.$toast.error(this.$t('reserva.titulo_especialidad'));
        res = false;
      }

      return res;
    },

    saveInfoToPatient() {
      if (this.validateData()) {
        const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
        this.isLoading = true;
        this.$http
          .post(saveStepToStepUrl, { id: authUser.id, usuario: this.usuario, step: 3 }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              if (this.myCroppa.img !== null) {
                const formData = new FormData();
                formData.append('usuario_id', authUser.id);
                formData.append('user_id', authUser.id);
                formData.append('from', 'steptostep');
                this.myCroppa.generateBlob(blob => {
                  formData.append('file', blob, 'filename.png');
                  this.$http
                    .post(editImageUserUrl, formData, { headers: getHeaderMultimedia() })
                    .then(response => {
                      if (response.status === 200 && parseInt(response.data.estado) === 1) {
                        this.usuario.fotoUrl = response.data.imagen;
                        this.modificando_imagen = false;
                        this.estado_croppa = 'ocultar_croppa';
                        this.myCroppa.img = null;
                        this.$emit('completeStep', 2);
                      }
                    })
                    .catch(err => {
                      this.$log.error(err);
                    })
                    .finally(() => {
                      this.isLoading = false;
                    });
                });
              } else {
                this.isLoading = false;
                this.$emit('completeStep', 2);
              }
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
          });
      }
    },

    removeSpecialty(item) {
      this.usuario.especialidades.splice(this.usuario.especialidades.indexOf(item), 1);
      this.usuario.especialidades = [...this.usuario.especialidades];
    },

    closeAfterSelect() {
      this.$refs.select.isMenuActive = false;
    },
  },
};
</script>
<style>
.center-cont {
  margin: 0;
}
</style>

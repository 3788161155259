<template>
  <modal
    :title="isValidCollegiateNumber && collegiateNumberAllowEmmit ? $t('common.emitir_receta') : $t('common.notice')"
    :show="show"
  >
    <div v-if="isValidCollegiateNumber && collegiateNumberAllowEmmit && !trialMode">
      <input-search-data
        :title="$t('common.paciente')"
        :placeholder="$t('recipe.search_patient')"
        :result-data="searchData"
        class="fixheight"
        @seloption="changeValue($event)"
      />

      <div v-if="error" class="error-msj">
        {{ error }}
      </div>

      <div class="actions">
        <v-btn color="primary" dark text @click="close">
          {{ $t('common.cancel') }}
        </v-btn>

        <v-btn
          :color="butColor"
          :disabled="disableBtn"
          :loading="loading"
          depressed
          class="white--text"
          @click="validateBeforeOpen"
        >
          {{ $t('common.continuar') }}
        </v-btn>
      </div>
    </div>

    <checking-collegiate-number v-else-if="isLoading" />
    <collegiate-msg v-else-if="!isValidCollegiateNumber" @closeModal="close" />
    <collegiate-not-allow-to-prescribe-msg v-else-if="!collegiateNumberAllowEmmit" @closeModal="close" />
    <trial-msg v-else-if="trialMode" @closeModal="close" />
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { getPatientDetailUrl, getHeader, checkCollegiateAllowPrescribe } from '@/config/config';
import { showIntercom, haveValidCollegiateNumber, isValidDocument } from '@/utils';
import CollegiateMsg from '@/components/treatments/CollegiateMsg.vue';
import TrialMsg from '@/components/treatments/TrialMsg.vue';
import CollegiateNotAllowToPrescribeMsg from '@/components/treatments/CollegiateNotAllowToPrescribeMsg.vue';
import CheckingCollegiateNumber from './CheckingCollegiateNumber';

export default {
  components: {
    CollegiateMsg,
    TrialMsg,
    CollegiateNotAllowToPrescribeMsg,
    CheckingCollegiateNumber,
  },
  model: {
    prop: 'show',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    professionalId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      error: null,
      searchData: [],
      disableBtn: true,
      loading: false,
      patientIdSelected: null,
      butColor: 'info',
      isValidCollegiateNumber: false,
      collegiateNumberAllowEmmit: true, // TODO: cambio temporal, revisar si arregla el bug del num de colegiado
      trialMode: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    }),
  },

  created() {
    if (this.authUser && this.authUser.trial) {
      this.trialMode = true;
    }

    this.isValidCollegiateNumber = haveValidCollegiateNumber();
    if (this.isValidCollegiateNumber) {
      this.checkCollegiateNumberAllowToPrescribe();
    }
    this.setButtonColor();
  },

  methods: {
    changeValue(id) {
      if (typeof id !== 'undefined') {
        this.disableBtn = false;
        this.patientIdSelected = id;
      }
    },

    onSubmit() {
      if (this.patientIdSelected !== null) {
        this.$emit('patientSelected', this.patientIdSelected);
      }
    },

    validateBeforeOpen() {
      this.loading = true;
      let validDocument = false;
      if (this.patientIdSelected !== null) {
        try {
          this.$http.get(getPatientDetailUrl + this.patientIdSelected, { headers: getHeader() }).then(response => {
            if (response.status === 200 && response.data) {
              validDocument = isValidDocument(response.body.data.documentType, response.body.data.dni);
              if (validDocument) {
                this.onSubmit();
                this.loading = false;
              } else {
                this.loading = false;
                this.$emit('showIncorrectDocumentMsj', this.patientIdSelected);
              }
            }
          });
        } catch (e) {
          this.loading = false;
          this.$emit('showIncorrectDocumentMsj', this.patientIdSelected);
          this.error = e.message;
        }
      }
    },

    close() {
      showIntercom();
      this.$emit('input', false);
    },

    setButtonColor() {
      if (this.appThemePrimary) {
        this.butColor = this.appThemePrimary;
      }
    },

    checkCollegiateNumberAllowToPrescribe() {
      this.isLoading = true;
      this.$http
        .post(checkCollegiateAllowPrescribe, { professional_id: 2 }, { headers: getHeader() })
        .then(res => {
          this.collegiateNumberAllowEmmit = res.body.allow;
        })
        .catch(err => {
          this.$log.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
}

.error-msj {
  color: #dc3545;
}

.fixheight {
  flex: unset !important;
}

::v-deep .v-application--wrap {
  min-height: unset !important;
}
</style>

<template>
  <v-container data-test="dashboard">
    <home-patient v-if="isPatient" />
    <home-professional v-else />
  </v-container>
</template>
<script>

export default {
  components: {
    HomePatient: () => import(/* webpackChunkName: "dashboard" */ './patients/Home.vue'),
    HomeProfessional: () =>
      import(/* webpackPrefetch: true, webpackChunkName: "dashboard" */ './professionals/Dashboard.vue'),
  },
  data: () => ({
    isPatient: null,
  }),
  created() {
    const authEHealth = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    if (authEHealth && authEHealth.isPatient) {
      this.isPatient = authEHealth.isPatient;
    }
  },
};
</script>

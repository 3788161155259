<template>
  <div class="px-5 pb-2">
    <v-form ref="billingDataForm" v-model="validForm" class="p-0">
      <v-layout v-if="data.payment_module === 1 && !loading" wrap>
        <v-flex v-if="companyBilling || (!companyBilling && !isAdmin)" sm12 lg12>
          <v-alert :value="true" type="info">
            {{ $t('steps.iban_payment_module_message') }}
          </v-alert>
        </v-flex>

        <v-flex v-if="representative_section" sm6 lg6>
          <v-text-field
            v-model="data.name"
            class="mr-2"
            :label="`${$t('patient.first_name')}*`"
            type="text"
            :rules="rules.requiredField"
            @change="modificarSlug"
          />
        </v-flex>

        <v-flex v-if="representative_section" sm6 lg6>
          <v-text-field
            v-model="data.surname"
            class="mr-2"
            :label="`${$t('patient.last_name')}*`"
            type="text"
            :rules="rules.requiredField"
            @change="modificarSlug"
          />
        </v-flex>

        <v-flex v-if="representative_section" sm6 lg6>
          <v-text-field
            v-model="data.id_document"
            class="mr-2"
            :label="`${$t('patient.document_id')}*`"
            type="text"
            :rules="rules.requiredField"
          />
        </v-flex>

        <v-flex v-if="representative_section" xs12 sm6 lg6>
          <v-menu
            ref="birthdateMenu"
            v-model="birthdateMenu"
            class="mr-2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            :return-value.sync="data.birthdate"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateFormatted"
                :label="`${$t('patient.birthdate')}*`"
                append-icon="mdi-calendar"
                readonly
                class="input_fnacimiento"
                :rules="rules.requiredField"
                v-on="on"
                @blur="date = parseDate(dateFormatted)"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="date"
              first-day-of-week="1"
              :locale="$i18n.locale"
              :min="datePickerMinDate"
              :max="dateMaxNacimiento"
              no-title
              scrollable
              @input="$refs.birthdateMenu.save(date)"
            />
          </v-menu>
        </v-flex>

        <v-flex sm9 lg9>
          <v-text-field
            v-model="data.address"
            class="mr-2"
            :label="companyBilling ? `${this.$t('steps.fiscal_address')}*` : `${this.$t('common.address')}*`"
            append-icon="mdi-map-marker"
            type="text"
            :rules="rules.requiredField"
          />
        </v-flex>

        <v-flex sm3 lg3>
          <v-text-field
            v-model="data.postalCode"
            class="mr-2"
            :label="`${this.$t('common.cp')}*`"
            append-icon="mdi-map-marker"
            type="text"
            :rules="rules.requiredField"
          />
        </v-flex>

        <v-flex sm6 lg6>
          <v-text-field
            v-model="data.city"
            class="mr-2"
            :label="`${this.$t('common.location')}*`"
            append-icon="mdi-map-marker"
            type="text"
            :rules="rules.requiredField"
          />
        </v-flex>

        <v-flex sm6 lg6>
          <v-text-field
            v-model="data.region"
            class="mr-2"
            :label="`${this.$t('common.province')}*`"
            append-icon="mdi-map-marker"
            type="text"
            :rules="rules.requiredField"
          />
        </v-flex>

        <v-flex v-if="!representative_section" sm12 lg12>
          <v-text-field
            v-model="data.bankNumber"
            class="mr-2"
            label="IBAN*"
            append-icon="mdi-bank"
            type="text"
            :rules="rules.requiredField"
            required
            @change="modifyIban"
          />
        </v-flex>

        <v-flex v-if="!companyBilling" sm6 lg6 class="pr-1">
          <v-btn
            style="width: 99%"
            block
            :color="dni_subido_anverso ? 'green darken-3' : 'primary'"
            dark
            @click="uploadDocument('anverso')"
            >{{ dni_subido_anverso ? $t('steps.dni_anverso_subido') : $t('steps.subir_dni_anverso') }}</v-btn
          >
          <input
            id="file_dni_anverso"
            ref="file_dni_anverso"
            type="file"
            accept="image/jpg, image/png"
            style="display: none"
            @change="onFileChangeDNI_anverso"
          />
          <v-progress-linear v-if="uploadDNIanverso" :value="procesoCarga" height="5" color="info" />
        </v-flex>

        <v-flex v-if="!companyBilling" sm6 lg6 class="pl-1">
          <v-btn
            style="width: 99%"
            block
            :color="dni_subido_reverso ? 'green darken-3' : 'primary'"
            dark
            @click="uploadDocument('reverso')"
            >{{ dni_subido_reverso ? $t('steps.dni_reverso_subido') : $t('steps.subir_dni_reverso') }}</v-btn
          >
          <input
            id="file_dni_reverso"
            ref="file_dni_reverso"
            type="file"
            accept="image/jpg, image/png"
            style="display: none"
            @change="onFileChangeDNI_reverso"
          />
          <v-progress-linear v-if="uploadDNIreverso" :value="procesoCarga" height="5" color="primary" />
        </v-flex>
        <v-flex v-if="companyBilling" sm6 lg6>
          <v-btn
            style="width: 99%"
            block
            :color="documento_tarjeta_identificacion ? 'green darken-3' : 'primary'"
            dark
            @click="uploadDocument('tarjeta')"
          >
            {{
              documento_tarjeta_identificacion
                ? $t('steps.tarjeta_identificacion_subido')
                : $t('steps.subir_tarjeta_identificacion')
            }}
          </v-btn>
          <input
            id="file_tarjeta_identificacion"
            ref="file_tarjeta_identificacion"
            type="file"
            accept="image/jpg, image/png, application/pdf"
            style="display: none"
            @change="onFileChangeTarjetaIdentificacion"
          />
          <v-progress-linear v-if="uploadTarjetaIdentificacion" :value="procesoCarga" height="5" color="primary" />
        </v-flex>

        <v-flex xs12 style="color: #aaa"> * {{ $t('steps.archivos_validos') }} </v-flex>
        <v-flex xs12 class="text-right">
          <v-btn depressed text @click="$emit('back-to-step', companyBilling ? 1 : 2)">{{
            $t('common.volver_atras')
          }}</v-btn>
          <v-btn depressed :color="companyBilling ? 'primary' : 'green'" dark @click="saveBillingData">{{
            companyBilling ? $t('common.continuar') : $t('common.save')
          }}</v-btn>
        </v-flex>
      </v-layout>
      <v-layout v-else wrap style="width: 720px; height: 200px">
        <v-flex xs12>
          <v-progress-linear :indeterminate="true" />
        </v-flex>
        <v-flex xs12>
          <v-alert :value="true" type="info">
            {{ $t('steps.saving_data') }}
          </v-alert>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { saveStepBillingUrl, uploadDocumentIdUrl, getHeader, getHeaderMultimedia } from '@/config/config';
import { datePickerMinDate } from '@/services/dateHelper';
import FormValidator from '@/mixins/FormValidation';

export default {
  name: 'StepBillingData',
  mixins: [FormValidator],
  props: {
    data: {
      type: Object,
      default: () => [],
    },
    companyBilling: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      dateFormatted: null,
      birthdateMenu: false,
      dateMaxNacimiento: new Date().toISOString().substr(0, 10),
      procesoCarga: 0,
      uploadDNIanverso: false,
      uploadDNIreverso: false,
      uploadTarjetaIdentificacion: false,
      loading: false,
      datePickerMinDate: datePickerMinDate(),
      validForm: false,
    };
  },

  computed: {
    ...mapState({
      appDisplayName: state => state.app.displayName,
      permissions: state => state.app.permissions,
    }),
    dni_subido_anverso: function() {
      return !this.companyBilling && this.data.photoIdentityDocumentFront !== '';
    },
    dni_subido_reverso: function() {
      return !this.companyBilling && this.data.photoIdentityDocumentReverse !== '';
    },
    documento_tarjeta_identificacion: function() {
      return (
        this.companyBilling &&
        this.data.documento_tarjeta_identificacion !== '' &&
        this.data.documento_tarjeta_identificacion !== null
      );
    },
    representative_section: function() {
      return !this.companyBilling && this.isAdmin;
    },
  },

  watch: {
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    birthdateMenu(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
    data: {
      handler: function(val) {
        this.date = val.birthdate;
      },
    },
  },

  methods: {
    handleClick: e => {
      console.log(e);
    },

    cerrarStep() {
      this.$emit('cerrar-step', true);
    },

    modificarSlug() {
      let slug = '';
      let nombreMarket = '';
      if (this.data.name !== '') {
        let nombre = this.data.name.trim();
        nombre = nombre.replace(/\s/g, '-');
        nombre = nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += nombre.toLowerCase() + '-';
        nombreMarket += this.data.name + ' ';
      }
      if (this.data.surname !== '') {
        let apellidos = this.data.surname.trim();
        nombreMarket += apellidos;
        apellidos = this.data.surname.replace(/\s/g, '-');
        apellidos = apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += apellidos.toLowerCase();
      }
      this.data.slug = slug;
      this.data.publicName = nombreMarket;
    },

    onFileChange() {
      this.disabledButton = true;
      const nuevaimagen = this.$refs.file.files[0];
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      const formData = new FormData();
      formData.append('file', nuevaimagen);
      formData.append('usuario_id', authUser.id);
      formData.append('user_id', authUser.id);
    },

    validateBillingData() {
      let res = true;
      if (!this.representative_section && !this.isValidIBANNumber(this.data.bankNumber)) {
        this.$toast.error(this.$t('steps.iban_required'));
        res = false;
      } else if (
        !this.companyBilling &&
        (this.data.photoIdentityDocumentFront === '' || this.data.photoIdentityDocumentReverse === '')
      ) {
        this.$toast.error(this.$t('steps.identification_doc_required'));
        res = false;
      } else if (
        this.companyBilling &&
        (this.data.documento_tarjeta_identificacion === '' || this.data.documento_tarjeta_identificacion === null)
      ) {
        this.$toast.error(this.$t('steps.identification_card_required'));
        res = false;
      }

      return res;
    },

    saveBillingData() {
      if (!this.$refs.billingDataForm.validate() || !this.validateBillingData()) {
        return;
      }

      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      this.loading = true;

      this.$http
        .post(
          saveStepBillingUrl,
          { id: authUser.id, data: this.data, saveInStripe: !this.companyBilling },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            const step = this.companyBilling ? 2 : 3;
            this.$emit('completeStep', step);
          } else {
            this.catchErrorResponse(response);
          }
        })
        .catch(err => {
          this.$log.error(err);
          this.$toast.error(this.$t('errors.try_again'));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    catchErrorResponse(response) {
      let message = this.$t('errors.try_again');
      if (typeof response.data.message !== 'undefined') {
        if (response.data.message === 'Invalid ES postal code') {
          message = this.$t('steps.cp_invalid');
        } else if (response.data.message.includes('account')) {
          message = this.$t('steps.iban_invalid');
        } else if (response.data.message.includes('a valid phone number')) {
          message = this.$t('registro.alert_phone_is_invalid');
        } else if (response.data.message.includes('test mode')) {
          message = response.data.message;
        }
      }

      this.$toast.error(message);
    },

    modifyIban() {
      this.esta_modificando = true;
      if (!this.isValidIBANNumber(this.data.bankNumber)) {
        this.$toast.error(this.$t('steps.iban_invalid'));
      }
    },

    isValidIBANNumber(input) {
      const CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
      };
      const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
      const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
        return letter.charCodeAt(0) - 55;
      });
      // final check
      return this.mod97(digits);
    },

    mod97(string) {
      let checksum = string.slice(0, 2),
        fragment;
      for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    uploadDocument(tipo) {
      if (tipo === 'anverso') {
        this.$refs.file_dni_anverso.click();
      } else if (tipo === 'reverso') {
        this.$refs.file_dni_reverso.click();
      } else if (tipo === 'tarjeta') {
        this.$refs.file_tarjeta_identificacion.click();
      }
    },

    onFileChangeDNI_anverso() {
      this.onFileChangeDocument('anverso');
    },

    onFileChangeDNI_reverso() {
      this.onFileChangeDocument('reverso');
    },

    onFileChangeTarjetaIdentificacion() {
      this.onFileChangeDocument('tarjeta');
    },

    onFileChangeDocument(tipo) {
      this.disabledButton = true;
      let nuevodoc = '';
      if (tipo === 'anverso') {
        this.uploadDNIanverso = true;
        nuevodoc = this.$refs.file_dni_anverso.files[0];
      } else if (tipo === 'reverso') {
        this.uploadDNIreverso = true;
        nuevodoc = this.$refs.file_dni_reverso.files[0];
      } else {
        this.uploadTarjetaIdentificacion = true;
        nuevodoc = this.$refs.file_tarjeta_identificacion.files[0];
      }
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      const formData = new FormData();
      formData.append('file', nuevodoc);
      formData.append('usuario_id', authUser.id);
      formData.append('user_id', authUser.id);
      formData.append('tipo', tipo);
      formData.append('from', 'steptostep');
      this.$http
        .post(uploadDocumentIdUrl, formData, {
          headers: getHeaderMultimedia(),
          progress: e => {
            if (e.lengthComputable) {
              this.procesoCarga = (e.loaded / e.total) * 100;
            }
          },
        })
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            if (tipo === 'anverso') {
              this.data.photoIdentityDocumentFront = response.data.doc;
              this.uploadDNIanverso = false;
            } else if (tipo === 'reverso') {
              this.data.photoIdentityDocumentReverse = response.data.doc;
              this.uploadDNIreverso = false;
            } else if (tipo === 'tarjeta') {
              this.data.documento_tarjeta_identificacion = response.data.doc;
              this.uploadTarjetaIdentificacion = false;
            }
          }
          this.procesoCarga = 0;
        });
    },
  },
};
</script>

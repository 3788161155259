<template>
  <v-dialog v-model="modalValue" max-width="1080px" persistent>
    <v-card>
      <v-card-title class="step-modal__header-container">
        <div class="step-modal__title-container">
          <div class="step-modal__title">
            <v-icon class="step-modal__icon">{{ iconName }}</v-icon> {{ title }}
            <span v-if="showSteps" class="step-modal__steps-counter"> ({{ currentStep }}/{{ steps }}) </span>
          </div>
          <div>
            <v-icon class="step-modal__close" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="step-modal__content">
        <slot />
      </v-card-text>
      <v-card-actions
        v-if="hasActions"
        :class="{
          'step-modal__actions': true,
          'justify-content-space-between': hasLeftActions,
          'justify-content-end': !hasLeftActions,
        }"
      >
        <slot name="left-actions" />
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'StepModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    steps: {
      type: Number,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showSteps: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    hasLeftActions() {
      return this.$slots['left-actions'];
    },
    hasActions() {
      return this.hasLeftActions || this.$slots['actions'];
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss">
$white: #ffffff;
$grey-light: #f1f1f1;
$primary-color: var(--v-primary-base);

.justify-content-end {
  justify-content: end;
}
.justify-content-space-between {
  justify-content: space-between;
}

.step-modal {
  &__header-container {
    background-color: $grey-light;
    padding-left: rem(8) !important;
    padding-right: rem(8) !important;

    @media only screen and (min-width: 768px) {
      padding-left: rem(32) !important;
      padding-right: rem(32) !important;
    }
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__icon {
    display: none !important;

    @media only screen and (min-width: 992px) {
      display: inline-flex !important;
      width: 2.5rem;
      height: 2.5rem;
      padding: 1rem;
      border-radius: 50%;
      background-color: $primary-color;
      font-size: 1.125rem;
      color: $white !important;
      text-align: center;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }

  &__close {
    font-size: 1.25rem !important;
  }

  &__steps-counter {
    margin-left: 0.25rem;
    font-weight: 100;
  }

  &__content {
    padding-left: rem(8) !important;
    padding-right: rem(8) !important;

    @media only screen and (min-width: 768px) {
      padding-left: rem(32) !important;
      padding-right: rem(32) !important;
    }
  }

  &__actions {
    padding-left: rem(8) !important;
    padding-right: rem(8) !important;
    padding-bottom: rem(8) !important;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media only screen and (min-width: 768px) {
      padding-left: rem(32) !important;
      padding-right: rem(32) !important;
      padding-bottom: rem(32) !important;
    }

    @media only screen and (max-width: 768px) {
      position: sticky;
      bottom: 0;
      background: white;
    }
  }
}
</style>

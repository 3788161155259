<template>
  <v-flex lg3 md3 sm6 xs6>
    <v-card class="wrapper" elevation="1" @click="$emit('click')">
      <div class="text--primary" style="height: 48px; max-height:48px">
        <v-icon class="icon">{{ iconName }}</v-icon
        ><slot />
      </div>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: 'QuickButton',
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  cursor: pointer;
  padding: 16px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--v-primary-base);
  }

  & > div {
    display: flex;
    flex-direction: center;
    align-items: center;
  }

  .icon {
    width: 40px;
    height: 40px;
    padding: 16px;
    border-radius: 50%;
    background-color: var(--v-primary-base);
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    margin-right: 16px;
  }

  @media screen and (max-width: 600px) {
    height: 132px;
    & > div {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .icon {
      margin: 0;
      margin-bottom: 0.6rem;
    }
  }
}
</style>

<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <div id="pageDashboard">
          <v-container grid-list-xl fluid>
            <Botonera
              :loading="loading"
              :user="usuario"
              @showDialogChoosePrescription="openDialogChoosePrescription"
              @showPatientModal="openSelectPatientMedicalTests"
            />
            <iframe :src="portalBoardUrl" style="position: relative; height: 80vh; width: 100%; border: 0;" />
<!--            <div v-if="loading" class="text-center pa-5">-->
<!--              <v-progress-circular width="2" color="primary" size="50" indeterminate />-->
<!--            </div>-->
<!--            <v-layout v-else wrap>-->
<!--              <v-flex lg6 sm12 md6 xs12>-->
<!--                <div v-if="toShow.nextConsultations" class="block_dashboard">-->
<!--                  <NextConsultations-->
<!--                    :title="$tc('common.prox_consultas', 2)"-->
<!--                    :consultas="nextConsultations"-->
<!--                    :loading="loadingConsultations"-->
<!--                    :company-name="authUser !== null ? authUser.nombreEmpresa : ''"-->
<!--                    :error="nextConsultationsError"-->
<!--                  />-->
<!--                </div>-->
<!--              </v-flex>-->
<!--              <v-flex lg6 sm12 md6 xs12 class="block_dashboard">-->
<!--                <div v-if="toShow.lastMessages" class="block_dashboard">-->
<!--                  <LastMessages-->
<!--                    :title="$t('common.ult_mensajes')"-->
<!--                    :consultas="lastMessages"-->
<!--                    :loading="loadingConsultations"-->
<!--                    :error="lastMessagesError"-->
<!--                  />-->
<!--                </div>-->
<!--              </v-flex>-->
<!--            </v-layout>-->
            <treatment-modal
              v-if="electronicPrescriptionAvailable "
              v-model="showModal"
              :user="user_id"
              :patient="patient_id"
              @openSelectPatient="openSelectPatientFromElectronicPrescription"
            />

            <SearchPatientModal
              v-model="showPatientModal"
              :professional-id="user_id"
              @patientSelected="toRequestMedicalTest($event)"
              @showIncorrectDocumentMsj="showIncorrectDocumentMsj"
            />
          </v-container>

          <v-dialog v-if="permissions.hideFormBooking !== true" v-model="dialogReservaFormacion" width="500">
            <v-card v-if="mostrar_especialidad">
              <v-card-title class="headline lighten-2">
                <v-spacer />
                {{ $t('message.welcome', { name: nombreEntorno }) }}
                <v-spacer />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12 class="texto-claro">
                    <p>
                      {{ $t('message.welcome', { name: nombreEntorno }) }}
                      {{ usuario.nombreMarket }}!
                      <img src="/static/fiesta.png" alt="bienvenido" width="25px" />
                    </p>
                  </v-flex>
                  <v-flex xs11 class="texto-claro">
                    <p>
                      {{ $t('message.estamos_encantados') }}<br />{{
                        $t('message.le_guiaremos', { name: nombreEntorno })
                      }}<br />
                    </p>
                  </v-flex>
                  <v-flex xs12>{{ $t('reserva.titulo_especialidad') }}:</v-flex>
                  <v-flex xs12>
                    <div slot="widget-content">
                      <v-autocomplete
                        v-model="usuario.especialidades"
                        :label="$t('user.especialidades') + ' *'"
                        :items="specialties"
                        :loading="loadingSpecialties"
                        :disabled="loadingSpecialties"
                        chips
                        item-text="name"
                        item-value="id"
                        item-valor="id"
                        multiple
                      >
                        <template slot="selection" slot-scope="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            color="blue lighten-4"
                            @click:close="removeEspecialidad(data.item.id)"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                        <template slot="item" slot-scope="data">
                          <template v-if="!isObject(data.item)">
                            <v-list-item-content v-text="data.item" />
                          </template>
                          <template v-else>
                            <v-list-item-content>
                              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="green darken-1" text @click.native="saveSpecialties()"
                  ><v-icon>mdi-chevron-right</v-icon> {{ $t('common.continuar') }}</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title class="headline lighten-2">
                <v-spacer />
                {{ $t('reserva.titulo') }}
                <v-spacer />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs8>
                    <p>{{ $t('reserva.texto1', { name: nombreEntorno }) }}</p>
                    <p>{{ $t('reserva.texto2') }}</p>
                  </v-flex>
                  <v-flex xs4>
                    <center>
                      <img src="/static/calendar.png" alt="calendario" width="85%" />
                    </center>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="teal lighten-2" dark @click="goToBooking">{{ $t('reserva.boton') }}</v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>

          <EmitParapharmacyForm
            v-if="dialogEmitParapharmacyModal"
            v-model="dialogEmitParapharmacyModal"
            :template-id="parapharmacyTemplate"
            :patient-id="selectedPatient"
          />

          <PrescriptionSelectorModal
            v-if="dialogChoosePrescription"
            v-model="dialogChoosePrescription"
            :user="usuario"
            :preselected-type="preselectedPrescriptionType"
            @showEPrescriptionModal="openElectronicPrescription"
            @showParapharmacyPrescriptionModal="showParapharmacyPrescriptionModal"
          />

          <div>
            <vue-stripe-checkout
              ref="checkoutRef"
              :image="stripe.image"
              :name="stripe.name"
              locale="es"
              :description="stripe.description"
              :currency="stripe.currency"
              :panel-label="stripe.titulo_boton"
              :amount="stripe.amount"
              :allow-remember-me="false"
              :email="stripe.email"
              @done="done"
              @opened="opened"
              @closed="closed"
              @canceled="canceled"
            />
          </div>

          <v-dialog v-model="dialogPago" dialog_delete max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('pago.titulo') }}</span>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <ul style="margin-left: 15px;">
                  <li>{{ producto_pago.nombre }}</li>
                  <li>{{ $t('common.precio') }}: {{ producto_pago.total }}{{ moneda }}</li>
                  <li>{{ $t('pago.ciclo') }}</li>
                </ul>
                <p><br />{{ $t('pago.explicacion') }}</p>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn color="grey darken-1" text @click="dialogPago = false"
                  ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
                >
                <v-btn
                  color="green darken-1"
                  text
                  @click="
                    dialogPago = false;
                    checkout();
                  "
                  ><v-icon>mdi-credit-card-outline</v-icon> {{ $t('common.continuar') }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <Welcome :dialog-welcome="dialogWelcome" @hideDialogWelcome="hideDialogWelcome" />
          <v-dialog
            v-if="authUser && authUser.rol === 2 && dialogStepToStep && !dialogWelcome"
            v-model="dialogStepToStep"
            persistent
            scrollable
            max-width="800px"
          >
            <Steptostep
              :usuario="usuario"
              :empresa="empresa"
              :especialidades="specialties"
              :is-admin="isAdmin"
              @completadoStepToStep="completadoStep"
              @cerrar-step="cerrarStep"
            />
          </v-dialog>

          <v-container v-if="mostrar_reconocimiento_pacientes" grid-list-xl fluid>
            <v-layout wrap>
              <v-flex lg12 sm12 xs12>
                <v-widget :title="$t('common.reconocimiento')" content-bg="white">
                  <div slot="widget-content">
                    <ol class="timeline timeline-activity timeline-point-sm timeline-content-right">
                      <li v-for="(item, index) in reconocimiento_pacientes" :key="index" class="timeline-block">
                        <div class="fecha_prox_consultas">
                          <time :datetime="item.fecha" class="icon">
                            <strong>{{ item.mes }}</strong>
                            <span>{{ item.dia }}</span>
                          </time>
                        </div>
                        <v-layout wrap style="margin-top: 0px;">
                          <v-flex xs12 sm7>
                            <div class="contenido_prox_consultas">
                              <span
                                class="subheading prox_consultas_nombre"
                                @click="$router.push('/paciente/' + item.paciente_id)"
                                >{{ item.paciente }}</span
                              >
                              <div class="text--secondary">{{ item.texto }}</div>
                            </div>
                          </v-flex>
                          <v-flex xs12 sm5 style="text-align: right; margin-right: 0px">
                            <span v-if="item.estado === 6">
                              <v-btn color="success" @click="mostrarDialogo(item.id, 2, item.paciente_id)">{{
                                $t('videoconsulta.ok')
                              }}</v-btn>
                              <v-btn color="error" @click="mostrarDialogo(item.id, 7, item.paciente_id)">{{
                                $t('videoconsulta.denegada')
                              }}</v-btn>
                            </span>
                            <span v-else>
                              <v-btn color="success" @click="mostrarDialogo(item.id, 1, item.paciente_id)">
                                {{ $t('common.apto') }}</v-btn
                              >
                              <v-btn color="error" @click="mostrarDialogo(item.id, 5, item.paciente_id)">
                                {{ $t('common.no_apto') }}</v-btn
                              >
                            </span>
                          </v-flex>
                        </v-layout>
                      </li>
                    </ol>
                  </div>
                </v-widget>
              </v-flex>
            </v-layout>
          </v-container>

          <div class="text-center">
            <v-dialog v-model="dialog_confirm.dialog" dialog_confirm width="500">
              <v-card>
                <v-card-title class="headline lighten-2">{{ dialog_confirm.pregunta }}</v-card-title>
                <v-divider />
                <div id="sectionfile">
                  <span class="mt-2 ml-1">{{ dialog_confirm.documento }}</span>
                  <input id="file" ref="file" class="mt-2" type="file" accept="application/pdf" />
                </div>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" dark text @click.native="dialog_confirm.dialog = false">{{
                    $t('common.cancel')
                  }}</v-btn>
                  <v-btn
                    color="primary"
                    dark
                    text
                    @click="
                      actualizarEstado(dialog_confirm.reconoc_id, dialog_confirm.estado, dialog_confirm.paciente_id)
                    "
                    >{{ $t('common.confirm') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <div class="text-center">
            <v-dialog v-model="dialog_delete.dialog" dialog_delete width="500">
              <v-card>
                <v-card-title class="headline lighten-2">{{ dialog_delete.pregunta }} </v-card-title>
                <v-divider />
                <v-card-text>
                  <p>{{ dialog_delete.texto }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" dark text @click.native="dialog_delete.dialog = false">{{
                    $t('common.cancel')
                  }}</v-btn>
                  <v-btn color="primary" dark text @click="deleteAppointment()">{{ $t('common.confirm') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <v-dialog v-model="dialog_patient_document.dialog" max-width="500">
            <patient-document-msg :patient_id="dialog_patient_document.patient_id" @close="closePatientDocumentMsg" />
          </v-dialog>

          <v-dialog v-model="dialogContracts.show" max-width="500">
            <pending-contracts
              :name="dialogContracts.name"
              :contract-id="dialogContracts.contractId"
              @close="closeDialogContracts"
            />
          </v-dialog>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Material from 'vuetify/es5/util/colors';
import { mapState, mapGetters, mapActions } from 'vuex';
import PendingContracts from '@/components/marketplace/PendingContracts.vue';
import { default as Botonera, ANALYTICAL_TEST, RX_TEST, OTHER_TEST } from '@/components/ehealth/dashboard/Botonera.vue';
import LastMessages from '@/components/ehealth/dashboard/LastMessages.vue';
import NextConsultations from '@/components/ehealth/dashboard/NextConsultations.vue';
import Steptostep from '@/components/ehealth/steptostep/Main.vue';
import EmitPrescriptionModal from '@/components/treatments/EmitPrescriptionModal.vue';
import TreatmentModal from '@/components/treatments/TreatmentModal.vue';
import VWidget from '@/components/VWidget.vue';
import { haveEprescription, haveEprescriptionActive, isValidDocument } from '@/utils';
import Welcome from '../../components/ehealth/dashboard/Welcome.vue';
import PatientDocumentMsg from '@/components/treatments/PatientDocumentMsg.vue';
import SearchPatientModal from '@/components/MedicalTests/SearchPatientModal';
import { baseNameRoutes } from '@/router/paths';
import EmitParapharmacyForm from '@/components/Parapharmacy/EmitForm/EmitParapharmacyForm.vue';
import { PRESCRIPTION_TYPES, default as PrescriptionSelectorModal } from '@/components/PrescriptionSelector/PrescriptionSelectorModal.vue';

import {
  activarSuscripcionUrl,
  asignarConsultaUrl,
  cancelarChequeoUrl,
  cancelarMensajeUrl,
  cancelarVideoconsultaUrl,
  dashBoardUrl,
  getHeader,
  getPatientDetailUrl,
  nameEnv,
  professionalHasSign,
  reconocimientosUrl,
  saveSpecialtiesUrl,
  saveStatusReco,
} from '@/config/config';
import Alerts from '@/mixins/Alerts';
import dayjs from "dayjs";
import PatientsSelector from "@/components/patients/PatientsSelector/PatientsSelector.vue";
import environment from "@/environment";

export default {
  name: 'HomeProfessional',
  components: {
    PendingContracts,
    SearchPatientModal,
    PatientDocumentMsg,
    Welcome,
    VWidget,
    Steptostep,
    Botonera,
    NextConsultations,
    LastMessages,
    TreatmentModal,
    EmitParapharmacyForm,
    PrescriptionSelectorModal
  },
  mixins: [Alerts],

  data() {
    return {
      loading: true,
      loadingConsultations: true,
      loadingSpecialties: true,
      color: Material,
      dialogReservaFormacion: false,
      dialogPago: false,
      dialogStepToStep: false,
      dialogWelcome: false,
      dialogAsignar: {
        dialog: false,
        id: 0,
        tipo: '',
        texto: '',
        titulo: '',
        profesional_id: 0,
        showSelectProfessional: false,
      },
      date: null,
      usuario: {},
      empresa: {},
      arrayEvents: null,
      nextConsultations: [],
      lastMessages: [],
      nextConsultationsError: false,
      lastMessagesError: false,
      listSpecialtiesError: false,
      toShow: {
        nextConsultations: false,
        lastMessages: false,
      },
      stripe: {
        image: 'https://docline-assets.s3.eu-west-3.amazonaws.com/logos/docline.png',
        name: '',
        description: '',
        titulo_boton: 'Pagar Suscripción',
        currency: 'Eur',
        amount: 0,
        email: JSON.parse(window.localStorage.getItem('auth_ehealth'))['email'],
      },
      producto_pago: {},
      specialties: [],
      mostrar_especialidad: false,
      es_rrhh:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 4,
      isAdmin: false,
      moneda: '€',
      nombreEntorno: nameEnv,
      reconocimiento_pacientes: [],
      mostrar_reconocimiento_pacientes: false,
      dialog_confirm: {
        dialog: false,
        texto: '',
        pregunta: '',
        reconoc_id: 0,
        estado: 0,
        paciente_id: 0,
        documento: '',
      },
      dialog_delete: {
        dialog: false,
        texto: '',
        pregunta: '',
        reconoc_id: 0,
        paciente_id: 0,
        type: '',
      },
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      intervalo: false,
      user_id: null,
      showModal: false,
      showEmitModal: false,
      showPatientModal: false,
      patient_id: 0,
      showElectronicPrescription: false,
      dialog_patient_document: {
        dialog: false,
        patient_id: null,
      },
      dialogContracts: {
        show: false,
        name: '',
        contractId: '',
      },
      selectedMedicalTestType: null,

      dialogEmitParapharmacyModal: false,
      dialogChoosePrescription: false,
      preselectedPrescriptionType: null,
      selectedPatient: null,
      parapharmacyTemplate: null,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions
    }),
    ...mapGetters({
      modalConditionsIsVisible: 'layout/getConditionsModalVisibility',
      contracts: 'user/getContracts',
    }),
    electronicPrescriptionAvailable() {
      return (
        (this.permissions.haveElectronicPrescription && !haveEprescriptionActive()) ||
        (this.permissions.haveElectronicPrescription && haveEprescriptionActive())
      );
    },
    pdfPrescriptionAvailable() {
      return this.permissions.settingsBook && this.permissions.prescriptions;
    },
    portalBoardUrl() {
      return `${environment.portalBoard}/embed/schedule`;
    }
  },

  watch: {
    modalConditionsIsVisible() {
      this.handleFirstLoad(true);
    },
  },

  mounted() {
    if (typeof this.permissions.haveElectronicPrescription !== 'undefined' && haveEprescription()) {
      this.showElectronicPrescription = this.permissions.haveElectronicPrescription;
    }

    if (haveEprescriptionActive()) {
      this.showTrialMsg = false;
    }

    if (this.es_rrhh) {
      this.pacientesReconocimiento();
    }

    this.dialog_confirm.documento = this.$t('documentos.subir_reconocimiento');
    this.isAdmin = this.authUser !== null && this.authUser.rol === 1;
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    this.user_id = authUser !== null && authUser.id !== null ? authUser.id : null;
    if (this.isAdmin) {
      this.$router.push('/professionals');
    }
    this.handlerDataDashboard();
    this.listConsultations();
    this.listSpecialties();
    this.pendingContracts();
  },

  methods: {
    environment() {
      return environment
    },
    ...mapActions({
      setUser: 'user/setUser',
    }),

    isObject(item) {
      return typeof item === 'object';
    },

    async checkout() {
      await this.$refs.checkoutRef.open();
    },

    removeEspecialidad(item) {
      this.usuario.especialidades.splice(this.usuario.especialidades.indexOf(item), 1);
      this.usuario.especialidades = [...this.usuario.especialidades];
    },

    saveSpecialties() {
      if (this.usuario.especialidades.length > 0) {
        this.$http
          .post(
            saveSpecialtiesUrl,
            {
              id: this.authUser.id,
              especialidades: this.usuario.especialidades,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.mostrar_especialidad = false;
            }
          })
          .catch(e => {
            this.toastError(this.$t('errors.try_again'));
            console.error(e);
          });
      } else {
        this.toastWarning(this.$t('errors.form_invalid'));
      }
    },

    done({ token, args }) {
      this.$http
        .post(activarSuscripcionUrl, { id: this.authUser.id, token: token, args: args }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.canceled();
            this.authUser.formPago = false;
            this.authUser.trial = false;
            this.authUser.formStepToStep = true;
            window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
            this.toastSuccess(this.$t('pago.cuenta_activada_correctamente'));
          } else {
            this.toastError(this.$t('errors.try_again'));
          }
        })
        .catch(e => {
          this.toastError(this.$t('errors.try_again'));
          console.error(e);
        });
    },

    opened() {
      // do stuff
    },

    closed() {
      // do stuff
    },

    canceled() {
      this.authUser.formPago = false;
      window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
      this.dialogPago = false;
    },

    functionEvents(date) {
      const [, , day] = date.split('-');
      return parseInt(day, 10) % 3 === 0;
    },

    formatFecha(fecha) {
      return fecha;
    },

    filterNextConsultations(videoConsultations) {
      const firstConsultations = videoConsultations.filter((vc) => {
        return dayjs().isBefore(dayjs(vc.fecha).add(vc.duracion, 'minute'));
      }).sort((a, b) => (dayjs(a.fecha).isAfter(dayjs(b.fecha)) ? 1 : -1)).slice(0, 5);

      return firstConsultations;
    },

    confirmarAsignar() {
      const is_valid =
        (this.dialogAsignar.showSelectProfessional && this.isAdmin && this.dialogAsignar.profesional_id > 0) ||
        !this.dialogAsignar.showSelectProfessional ||
        !this.isAdmin;
      if (is_valid) {
        this.dialogAsignar.dialog = false;
        this.$http
          .post(
            asignarConsultaUrl,
            {
              id: this.authUser.id,
              consultation_id: this.dialogAsignar.id,
              type: this.dialogAsignar.tipo,
              professional_id: this.dialogAsignar.profesional_id,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              const text_snackbar =
                this.dialogAsignar.tipo === 'sms'
                  ? this.$t('asignar.enviado_correctamente')
                  : this.$t('asignar.asignada_correctamente');
              this.toastSuccess(text_snackbar);
              if (this.dialogAsignar.tipo === 'message' && !this.isAdmin) {
                setTimeout(() => {
                  this.$router.push('/mensajeria/0_' + this.dialogAsignar.id).catch(e => console.error(e));
                }, 2000);
              } else if (this.dialogAsignar.tipo !== 'sms') {
                this.nextConsultations = this.filterNextConsultations(response.data.prox_consultas);
              }
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
          })
          .catch(e => {
            this.toastError(this.$t('errors.try_again'));
            console.error(e);
          });
      } else {
        this.toastError(this.$t('errors.form_invalid'));
      }
    },

    goToBooking() {
      this.$router.push('/reserva');
    },

    cerrarStep() {
      this.dialogStepToStep = false;
    },

    completadoStep() {
      this.toastSuccess(this.$t('success.save_common'));
      this.authUser.formStepToStep = false;
      window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
      this.dialogStepToStep = false;

      setTimeout(() => {
        this.$router.push('/ajustes/firma').catch(e => console.error(e));
      }, 2000);
    },

    hideDialogWelcome() {
      this.dialogWelcome = false;
      this.authUser.formWelcomeModal = false;
      window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
    },

    pacientesReconocimiento() {
      this.$http
        .get(reconocimientosUrl, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.reconocimiento_pacientes = response.data.reconocimientos;
            this.mostrar_reconocimiento_pacientes = this.reconocimiento_pacientes.length > 0;
          }
        })
        .catch(err => {
          if (err.status === 401) {
            localStorage.removeItem('auth_ehealth');
            this.$router.push('Login');
          }
        });
    },

    actualizarEstado(reconoc_id, estado, id_paciente) {
      const pdf = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append('reconocimiento_id', reconoc_id);
      formData.append('estado', estado);
      formData.append('id_paciente', id_paciente);
      if (typeof pdf !== 'undefined') {
        formData.append('file', pdf);
      }

      this.$http
        .post(saveStatusReco, formData, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.pacientesReconocimiento();
            this.dialog_confirm.dialog = false;
            this.toastSuccess(this.$t('success.edit_patient'));
          } else {
            this.toastError(this.$t('errors.edit_patient'));
          }
        })
        .catch(err => {
          this.$log.error(err);
        });
    },

    mostrarDialogo(reconoc_id, estado, paciente_id) {
      this.dialog_confirm.dialog = true;
      this.dialog_confirm.texto = this.$t('mensajeria.confirmar_delete_file');
      this.dialog_confirm.estado = estado;
      this.dialog_confirm.reconoc_id = reconoc_id;
      this.dialog_confirm.paciente_id = paciente_id;
      this.dialog_confirm.pregunta =
        estado === 5 ? this.$t('patient.aceptar_cambios_noapto') : this.$t('patient.aceptar_cambios_apto');
      this.dialog_confirm.documento = this.$t('documentos.subir_reconocimiento');

      if (estado === 7) {
        this.dialog_confirm.pregunta = this.$t('patient.aceptar_cambios_denegado');
        this.dialog_confirm.documento = this.$t('documentos.subir_documento');
      } else if (estado === 2) {
        this.dialog_confirm.pregunta = this.$t('patient.aceptar_cambios_rechazo');
        this.dialog_confirm.documento = this.$t('documentos.subir_documento');
      }
    },

    dialogDeleteAppointment(data) {
      this.dialog_delete.dialog = true;
      this.dialog_delete.pregunta = this.$t('videoconsulta.confirm_delete');
      this.dialog_delete.texto = this.$t('videoconsulta.notification_email');
      this.dialog_delete.reconoc_id = data.appointment_id;
      this.dialog_delete.paciente_id = data.patient_id;
      this.dialog_delete.type = data.type;
    },

    dialogAssignAppointment(data, text, title) {
      this.dialogAsignar.texto = text;
      this.dialogAsignar.id = data.appointment_id;
      this.dialogAsignar.tipo = data.type;
      this.dialogAsignar.dialog = true;
      this.dialogAsignar.titulo = title;
      this.dialogAsignar.showSelectProfessional = data.selectProfessional;
    },

    confirmAppointment(data) {
      if (data.action === 'delete') {
        this.dialogDeleteAppointment(data);
      } else if (data.action === 'assign') {
        this.dialogAssignAppointment(data, this.$t('asignar.texto_video'), this.$t('asignar.titulo'));
      }
    },

    enviarSms(id) {
      this.dialogAssignAppointment(
        { appointment_id: id, type: 'sms', selectProfessional: false },
        this.$t('asignar.texto_sms'),
        this.$t('asignar.titulo_sms')
      );
    },

    deleteAppointment() {
      this.dialog_delete.dialog = false;
      if (this.dialog_delete.type === 'message') {
        this.$http
          .post(
            cancelarMensajeUrl,
            {
              id: this.authUser.id,
              mensaje_id: this.dialog_delete.reconoc_id,
              patient_id: this.dialog_delete.paciente_id,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.toastSuccess(this.$t('success.delete_common'));
              this.listConsultations();
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
          })
          .catch(e => {
            this.toastError(this.$t('errors.try_again'));
            console.error(e);
          });
      } else if (this.dialog_delete.type === 'mdi-file-plus') {
        this.$http
          .post(
            cancelarChequeoUrl,
            {
              user_id: this.authUser.id,
              chequeo_id: this.dialog_delete.reconoc_id,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.toastSuccess(this.$t('success.delete_common'));
              this.listConsultations();
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
          })
          .catch(e => {
            this.toastError(this.$t('errors.try_again'));
            console.error(e);
          });
      } else {
        this.$http
          .post(
            cancelarVideoconsultaUrl,
            {
              user_id: this.authUser.id,
              videoconsulta_id: this.dialog_delete.reconoc_id,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.toastSuccess(this.$t('success.delete_common'));
              this.listConsultations();
            } else {
              this.toastError(this.$t('errors.try_again'));
            }
          })
          .catch(e => {
            this.toastError(this.$t('errors.try_again'));
            console.error(e);
          });
      }
    },

    pendingContracts() {
      if (this.contracts.length > 0) {
        this.dialogContracts.show = true;
        this.dialogContracts.name = this.contracts[0].senderPlatform;
        this.dialogContracts.contractId = this.contracts[0].id;
      }
    },

    async listConsultations() {
      if (this.isAdmin) {
        return;
      }
      await this.$http
        .get(`${dashBoardUrl}/consultations`, { headers: getHeader() })
        .then(response => {
          this.nextConsultations = this.filterNextConsultations(response.data.nextConsultations)
          this.lastMessages = response.data.lastMessages;
        })
        .catch(e => {
          this.nextConsultationsError = true;
          this.lastMessagesError = true;
        })
        .finally(() => {
          this.loadingConsultations = false;
        });
    },

    async listSpecialties() {
      this.loadingSpecialties = true;
      await this.$http
        .get(`${dashBoardUrl}/specialties`, { headers: getHeader() })
        .then(response => {
          this.specialties = response.data.specialties;
        })
        .catch(e => {
          this.listSpecialtiesError = true;
        })
        .finally(() => {
          this.loadingSpecialties = false;
        });
    },

    handlerDataDashboard() {
      this.$http
        .get(dashBoardUrl, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.loading = false;

            this.authUser.servicios_activos = response.data.servicios_activos;
            this.authUser.landing = response?.data?.usuario?.landing ?? null;
            window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
            this.setUser(response.data.usuario);

            const smsNotificationsEnabled = response.body.sms_service ?? true;
            window.localStorage.setItem('smsNotificationsEnabled', JSON.stringify(smsNotificationsEnabled));

            this.toShow.lastMessages = this.permissions.messaging;
            this.toShow.nextConsultations = this.permissions.videoConsultation !== false;

            this.usuario = response.data.usuario;
            if (this.authUser && this.authUser.trial && this.$route.name !== 'Login') {
              if (this.authUser.formPago) {
                this.dialogPago = true;
                this.producto_pago = response.data.producto_pago;
                this.stripe.amount = parseFloat(this.producto_pago.total) * 100;
                this.stripe.name = this.$t('pago.titulo_stripe');
                this.stripe.titulo_boton = this.$t('pago.pagar_suscripcion');
                this.stripe.description = this.producto_pago.nombre;
              } else {
                if (this.permissions.hideTrialMessage !== true) {
                  const initDate = new Date(this.authUser.start_trial);
                  const endDate = new Date();
                  const diff = endDate - initDate;
                  const numDays = 16 - Math.round(diff / (1000 * 60 * 60 * 24));
                  const text_snackbar = this.$tc('message.cuenta_trial', numDays, { count: numDays });
                  this.toastWarning(this.$t(text_snackbar));
                }

                this.dialogReservaFormacion = this.authUser.formReserva;
              }
            } else {
              this.handleFirstLoad(true);

              if (response.data.empresa) {
                this.empresa = response.data.empresa;
              }
              this.date = this.usuario.fechaNacimiento;
            }
            this.mostrar_especialidad = this.usuario.especialidades.length <= 0;
          }
        })
        .catch(err => {
          if (err.status === 401) {
            localStorage.removeItem('auth_ehealth');
            this.$router.push('Login').catch(e => console.error(e));
          }
        });
    },

    handleFirstLoad(firstLoad) {
      if (!firstLoad || this.modalConditionsIsVisible) {
        return;
      }

      this.$nextTick(() => {
        this.dialogStepToStep = this.authUser.formStepToStep && window.screen.width > 600 && this.authUser.rol === 2;
        this.dialogWelcome =
          typeof this.authUser.formWelcomeModal !== 'undefined' && this.authUser.formWelcomeModal === true;
      });
    },

    openTreatmentModal(patient) {
      let validDocument = false;
      if (patient !== null) {
        try {
          this.$http.get(getPatientDetailUrl + patient, { headers: getHeader() }).then(response => {
            if (response.status === 200 && response.data) {
              validDocument = isValidDocument(response.body.data.documentType, response.body.data.dni);
              if (validDocument) {
                this.patient_id = patient;
                this.showModal = true;
              } else {
                this.showIncorrectDocumentMsj(patient);
              }
            }
          });
        } catch (e) {
          this.showIncorrectDocumentMsj(patient);
        }
      }
    },

    toRequestMedicalTest(id) {
      this.patient_id = id;
      this.showEmitModal = false;

      let routeToPush = '';

      switch (this.selectedMedicalTestType) {
        case ANALYTICAL_TEST:
          routeToPush = baseNameRoutes.requestAnalyticalTest;
          break;
        case RX_TEST:
          routeToPush = baseNameRoutes.requestRxTest;
          break;
        case OTHER_TEST:
          routeToPush = baseNameRoutes.requestOtherTest;
          break;
      }

      this.$router.push({
        name: routeToPush,
        query: {
          patient: id,
        },
      });
    },

    openSelectPatientFromElectronicPrescription() {
      this.showModal = false;
      this.preselectedPrescriptionType = PRESCRIPTION_TYPES.ELECTRONIC;
      this.dialogChoosePrescription = true;
    },

    async openSelectPatientMedicalTests(medicalTestType) {
      try {
        await this.professionalHasSign();
      } catch (e) {
        await this.$router.push({ name: baseNameRoutes.signed });
        return;
      }

      this.selectedMedicalTestType = medicalTestType;
      this.showPatientModal = true;
    },

    async professionalHasSign() {
      const response = await this.$http.get(professionalHasSign, { headers: getHeader() });
      return response.status === 200;
    },

    showIncorrectDocumentMsj(patient_id = null) {
      this.dialog_patient_document.dialog = true;
      this.dialog_patient_document.patient_id = patient_id;
    },

    closePatientDocumentMsg() {
      this.dialog_patient_document.dialog = false;
    },

    closeDialogContracts() {
      this.dialogContracts.show = false;
    },

    showParapharmacyPrescriptionModal(patient, template) {
      this.selectedPatient = patient;
      this.parapharmacyTemplate = template;
      this.preselectedPrescriptionType = null;
      this.dialogChoosePrescription = false;
      this.dialogEmitParapharmacyModal = true;
    },

    openElectronicPrescription(patient) {
      this.dialogChoosePrescription = false;
      this.preselectedPrescriptionType = null;
      this.openTreatmentModal(patient);
    },

    openDialogChoosePrescription() {
      this.dialogChoosePrescription = true;
    },
  },

  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalo);
    next();
  },
};
</script>
<style lang="css">
.btn_home {
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 22px !important;
  letter-spacing: normal !important;
  font-family: 'Roboto', sans-serif !important;
  margin-top: -5px;
  margin-bottom: -5px;
}
.fecha_prox_consultas {
  margin-right: 5px;
  margin-bottom: 12px;
}

time.icon {
  font-size: 1em; /* change icon size */
  display: block;
  position: relative;
  width: 4em;
  height: 4em;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon * {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  line-height: 1.2em;
  text-align: center;
}

time.icon strong {
  position: absolute;
  top: 0;
  padding: 0;
  color: #fff;
  background-color: #007cd1;
  border-bottom: 1px dashed #3a59b4;
  box-shadow: 0 2px 0 #3090d8;
}

time.icon em {
  position: absolute;
  bottom: 0.3em;
  color: #3090d8;
}

time.icon span {
  width: 100%;
  font-size: 2.5em;
  letter-spacing: -0.05em;
  padding-top: 0.45em;
  color: #2f2f2f;
}
.prox_consultas_nombre {
  cursor: pointer;
}
.prox_consultas_nombre:hover {
  color: #0a2953;
}
.contenido_prox_consultas {
  margin-top: -10px;
}
.icon_recibido {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  color: #ff9800 !important;
}
.icon_enviado {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #4caf50 !important;
}

.icono_video {
  color: #00b0ff !important;
}

.icono_chequeo {
  color: #4caf50 !important;
}

.icono_mensaje {
  color: #da657e !important;
}

.fecha_ult_mensajes {
  color: #51565f;
  font-size: 13px;
}

.md-card-profile {
  width: 96%;
  margin: -50px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_marketplace {
  margin-top: 40px;
}
.foto_usuario:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}

.texto-claro {
  color: #777;
}

#sectionfile {
  width: 80%;
  margin: 2rem auto;
}

.block_dashboard {
  margin-bottom: 10px;
  min-height: 5.8em;
}
</style>
